export const state = () => ({
  reload_at: null,
  client_trend_at: null,
  cl_payment_at: null
})
export const mutations = {
  updateReload(state) {
    state.reload_at = Date.now()
  },
  updateClientTrend(state) {
    state.client_trend_at = Date.now()
  },
  updateClPayment(state) {
    state.cl_payment_at = Date.now()
  }
}
