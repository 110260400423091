
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import dayjs from 'dayjs';
import { goal_term, form_config } from '~/utils/models'
import Common from '~/mixins/common'
import ImageFileInput from '~/components/util/ImageFileInput'

export default {
  mixins: [Common],
  components: {
    ImageFileInput,
  },
  props: {
  },
  data() {
    return {
      // modal
      edit_mode: "create",
      base_goal_term: goal_term,
      showModal: false,
      modalTitle: null,
      edit_goal_term: null,

      start_front_img: null,
      start_side_img: null,
      start_back_img: null,
      end_front_img: null,
      end_side_img: null,
      end_back_img: null,
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    new(){
      this.edit_mode = "create"
      this.modalTitle = "新規作成"
      this.start_front_img = null
      this.start_side_img = null
      this.start_back_img = null
      this.end_front_img = null
      this.end_side_img = null
      this.end_back_img = null
      this.edit_goal_term = _.cloneDeep(this.base_goal_term)
      this.edit_goal_term.start_date = dayjs().format('YYYY-MM-DD')
      this.edit_goal_term.goal_date = dayjs().add(3, "month").format('YYYY-MM-DD')
      this.showModal = true
    },
    edit(goal_term){
      this.edit_mode = "update"
      this.modalTitle = `${goal_term.title}の編集`
      this.start_front_img = null
      this.start_side_img = null
      this.start_back_img = null
      this.end_front_img = null
      this.end_side_img = null
      this.end_back_img = null
      this.edit_goal_term = _.cloneDeep(goal_term)
      this.showModal = true
    },
    setForm(){
      let self = this
      // form
      const formData = new FormData();
      // start_front_img
      if(self.start_front_img){
        formData.append("start_front_img", self.start_front_img)
      }else{
        if(!self.edit_goal_term.start_front_img) formData.append("start_front_img", "null")    
      }
      // start_side_img
      if(self.start_side_img){
        formData.append("start_side_img", self.start_side_img)
      }else{
        if(!self.edit_goal_term.start_side_img) formData.append("start_side_img", "null")    
      }
      // start_back_img
      if(self.start_back_img){
        formData.append("start_back_img", self.start_back_img)
      }else{
        if(!self.edit_goal_term.start_back_img) formData.append("start_back_img", "null")    
      }
      // end_front_img
      if(self.end_front_img){
        formData.append("end_front_img", self.end_front_img)
      }else{
        if(!self.edit_goal_term.end_front_img) formData.append("end_front_img", "null")    
      }
      // end_side_img
      if(self.end_side_img){
        formData.append("end_side_img", self.end_side_img)
      }else{
        if(!self.edit_goal_term.end_side_img) formData.append("end_side_img", "null")    
      }
      // end_back_img
      if(self.end_back_img){
        formData.append("end_back_img", self.end_back_img)
      }else{
        if(!self.edit_goal_term.end_back_img) formData.append("end_back_img", "null")    
      }
      // other
      let goal_term_obj = JSON.stringify(self.edit_goal_term)
      formData.append('goal_term_obj', goal_term_obj)
      return formData
    },
    async create(){
      let self = this
      this.$bvModal.msgBoxConfirm('新規作成すると、進行中のチャレンジはペンディングされます。', {
        title: "新規作成してよろしいですか？",
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          let formData = this.setForm()
          formData.append('client_id', self.selectedClient.id)
          self.$store.commit("loading/start", true)
          await httpClient
            .post(`/cmp/floor/goal_terms.json`, formData, form_config)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.showModal = false
                self.$emit("updated", res.data.data.goal_term)
                window.storeCtl.commit("alert/setSuccess", "作成しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    async disactive(){
      let self = this
      this.$bvModal.msgBoxConfirm('ペンディングすると、顧客トップから外れます。', {
        title: "ペンディングにしてよろしいですか？",
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start", true)
          await httpClient
            .delete(`/cmp/floor/goal_terms/${self.edit_goal_term.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.showModal = false
                self.$emit("updated")
                window.storeCtl.commit("alert/setSuccess", "更新しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
          }
      })
    },

    async done(){
      this.$bvModal.msgBoxConfirm('完了すると、比較表示に完了時のデータが表示されます', {
        title: "完了にしてよろしいですか？",
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          this.update("done")
        }
      })
    },

    async update(type = "save"){
      let self = this
      let formData = this.setForm()
      formData.append("action_type", type)
      self.$store.commit("loading/start", true)
      await httpClient
        .put(`/cmp/floor/goal_terms/${self.edit_goal_term.uid}.json`, formData, form_config)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            self.showModal = false
            self.$emit("updated", res.data.data.goal_term)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  }
}
