
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import SideMenu from '~/components/util/SideMenu'
import UserIcon from '~/components/util/UserIcon'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ChatRoom from '~/components/common/chat/Room'

export default {
  name: 'default',
  mixins: [Common],
  components: {
    Alert,
    Loading,
    UserIcon,
    SideMenu,
    ChatRoom
  },
  data() {
    return {
      showSettingModal: false,
      version: !!process.env.VERSION ? Number(process.env.VERSION) : 0,

      rightDrawer: false,

      chat_room_interval: null,
      chat_room_pages: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null
      },
      current_chat_rooms: []
    }
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm

    // ルーム検索
    await self.chatRoomSearh()
    if (self.current_chat_rooms.length > 0)
      self.$refs.chat_room.selectChatRoom(self.current_chat_rooms[0])

    // rightDrawer
    if (self.isPC) {
      console.log('no pc')
      setTimeout(() => {
        self.drawer = true
        self.rightDrawer = true
        console.log('no pc ', self.rightDrawer)
      }, 100 * 1)
    }

    self.chat_room_interval = setInterval(() => {
      self.chatRoomSearh()
    }, 5 * 1000)
  },
  destroyed: function () {
    clearInterval(this.chat_room_interval)
  },
  wath: {},
  computed: {
    drawer: {
      get() {
        return this.$store.state.devise.drawer
      },
      set(newValue) {
        this.$store.commit('devise/setDrawer', newValue)
      }
    }
  },
  methods: {
    clickDrower() {
      this.$store.commit('devise/setDrawer', !this.$store.state.devise.drawer)
    },
    async reaload() {
      let self = this
      self.$store.commit('loading/start')
      await self.chatRoomSearh()
      self.$refs.chat_room.search(1)
      self.$store.commit('loading/stop')
    },
    async chatRoomSearh() {
      let self = this
      await httpClient
        .get(`/cmp/floor/chat_rooms.json`, {
          params: {
            page: self.chat_room_pages.current_page
          }
        })
        .then(async res => {
          if (res.data.status === 'success') {
            self.current_chat_rooms = res.data.data.chat_rooms
          } else {
            window.storeCtl.commit('alert/setError', res.data.message)
          }
        })
    }
  }
}
