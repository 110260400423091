
import Common from '~/mixins/common'
import Calucurator from '~/components/common/cl_payment/Calucurator.vue'
import _ from 'lodash';

export default {
  mixins: [Common],
  components: {
    Calucurator,
  },
  props: {
  },
  watch: {
  },
  data() {
    return {
      canOnline: false,
      showCalculateModal: false,
      item_price_sum: 0,
      azukariPrice: 0,
      selectedPaymentMethod: null,
    }
  },
  async mounted() {
  },
  computed: {
    //　会計モーダル
    manSet(){
      let man = parseInt(this.item_price_sum / 10000)
      let amari = this.item_price_sum % 10000
      let result_man = amari > 0 ? man + 1 : man
      return result_man * 10000
    },
    senSet(){
      let sen = parseInt(this.item_price_sum / 1000)
      let amari = this.item_price_sum % 1000
      let result_sen = amari > 0 ? sen + 1 : sen
      return result_sen * 1000
    },
    otsuriPrice(){
      return this.azukariPrice - this.item_price_sum
    },
    filteredAzukariPrice(){
      if(this.azukariPrice === null) return 0
      return this.azukariPrice
    }
  },
  methods: {
    openModal(item_price_sum, canOnline){
      this.canOnline = canOnline
      this.item_price_sum = item_price_sum
      if(canOnline){
        this.changePaymentMethod({id: null, is_cash: false})
      }else{
        this.changePaymentMethod(this.payment_methods[0])
      }
      this.showCalculateModal = true
    },
    closeModal(){
      this.showCalculateModal = false
    },
    changePaymentMethod(pm){
      this.selectedPaymentMethod = pm
      if(this.selectedPaymentMethod.is_cash){
        this.azukariPrice = 0
      }else{
        this.azukariPrice = _.cloneDeep(this.item_price_sum)
      }
    },
    changeAzukariNumber(number){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = number
    },
    addManSet(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.manSet
    },
    addSenSet(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.senSet
    },
    addZankin(){
      if(!this.selectedPaymentMethod.is_cash) return
      this.azukariPrice = this.item_price_sum
    },
    submitPay(){
      let self = this
      this.$emit("submitPay",{
        price_sum: self.item_price_sum,
        azukari_price: self.azukariPrice,
        otsuri_price: self.otsuriPrice,
        payment_method_id: self.selectedPaymentMethod.id,
        payment_method_name: self.selectedPaymentMethod.name,
        payment_with_online: self.selectedPaymentMethod.id === null ? true : false,
      });
    },
  },
}
