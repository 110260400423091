
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

export default {
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    isThisLastWeek(data){
      if(dayjs(data.week).isBefore(dayjs().startOf('week'))){
        return true
      }else{
        return false
      }
    }
  },
}
