import { render, staticRenderFns } from "./Kaikei.vue?vue&type=template&id=5f7182c4&scoped=true&lang=pug"
import script from "./Kaikei.vue?vue&type=script&lang=js"
export * from "./Kaikei.vue?vue&type=script&lang=js"
import style0 from "./Kaikei.vue?vue&type=style&index=0&id=5f7182c4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7182c4",
  null
  
)

export default component.exports