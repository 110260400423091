export const state = () => ({
  isLoading: false,
  all_display: false,
  text: '処理しています'
})
export const getters = {
  isLoading(state) {
    return state.isLoading
  }
}
export const mutations = {
  start(state, all_display = false) {
    state.all_display = all_display
    state.text = '処理しています'
    state.isLoading = true
  },
  updateText(state, text) {
    state.text = text
  },
  stop(state) {
    state.all_display = false
    state.text = '処理しています'
    state.isLoading = false
  }
}
