import { httpClient } from '~/utils/axios'
import _ from 'lodash'

const getDefaultState = () => {
  return {
    // マスターデータ
    companies: [],
    staffs: [],
    calendars: [],
    resources: [],
    courses: [],
    menus: [],
    sections: [],
    payment_methods: [],
    item_categories: [],
    items: [],
    training_categories: [],
    training_events: [],
    training_machines: [],
    training_muscles: [],
    time_zones: [],
    surveys: [],
    reserve_settings: [],

    // 複数店舗用
    company_groups: [],
    all_calendars: [],
    all_resources: [],

    // 端末固有設定
    latest_clients: [],
    selectedClient: null,
    calendar_ids: [],
    calendar_view_type: 'resourceTimeGridFourDay',
    calendar_only_business_hours: true,
    calendar_show_extra_days: true,
    calendar_start_on_sunday: true,
    calendar_resource_mode: 'calendar',
    calendar_duration: 15,
    reverve_check_has_ticket: false,
    reserve_push: false,
    showEventSetDetail: false,
    clientLists: []
  }
}

export const state = () => getDefaultState()
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setCompanies(state, companies) {
    state.companies = companies
  },
  setStaffs(state, staffs) {
    state.staffs = staffs
  },
  setCalendars(state, calendars) {
    calendars = _.orderBy(calendars, ['sort', 'id'], ['asc', 'desc'])
    state.calendars = calendars
  },
  setResources(state, resources) {
    resources = _.orderBy(resources, ['sort', 'id'], ['asc', 'desc'])
    state.resources = resources
  },
  setCourses(state, courses) {
    courses.forEach(c => {
      c.label = `${c.name_inner}/${c.price}円`
    })
    courses = _.orderBy(courses, ['sort', 'id'], ['asc', 'desc'])
    state.courses = courses
  },
  setMenus(state, menus) {
    menus.forEach(m => {
      let payment_type = `${m.need_ticket_size}枚`
      if (m.pay_as_you_go) {
        if (m.can_pay_onshop) {
          payment_type = `${m.price}円(現地)`
        } else {
          payment_type = `${m.price}円(事前)`
        }
      }
      let label = `${m.name_inner} / ${m.reserve_min}分 / ${payment_type} / ${m.max_seat_size}人`
      if (m.option_settings.use_menu_option)
        label = `${label} / オプ${m.option_settings.menu_option_price}円`
      if (m.option_settings.use_calendar_option) label = `${label} / 指名`
      if (m.restrict_resource) label = `${label} / 室限`
      m.label = label
    })
    menus = _.orderBy(menus, ['sort', 'id'], ['asc', 'desc'])
    state.menus = menus
  },
  setSections(state, sections) {
    sections = _.orderBy(sections, ['sort', 'id'], ['asc', 'desc'])
    state.sections = sections
  },
  setPaymentMethods(state, payment_methods) {
    payment_methods = _.orderBy(
      payment_methods,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.payment_methods = payment_methods
  },
  setItemCategories(state, item_categories) {
    // item_categories.forEach(it => {
    //   it.label = `${it.name_inner} / ${it.price}円(${it.tax_rate}%) / ${it.manage_stock ? '在庫:' + it.stock_size + '個' : null}`
    // })
    item_categories = _.orderBy(
      item_categories,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.item_categories = item_categories
  },
  setItems(state, items) {
    items.forEach(it => {
      it.label = `${it.name_inner} / ${it.price}円(${it.tax_rate}%) / ${it.manage_stock ? '在庫:' + it.stock_size + '個' : null}`
    })
    items = _.orderBy(items, ['sort', 'id'], ['asc', 'desc'])
    state.items = items
  },
  setTrainingCategories(state, training_categories) {
    training_categories = _.orderBy(
      training_categories,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_categories = training_categories
  },
  setTrainingEvents(state, training_events) {
    training_events.forEach(it => {
      it.label = `${it.name}`
      if (it.name_kana) it.label = `${it.label} / ${it.name_kana}`
      if (it.level) it.label = `${it.label} / レベル：${it.level}`
    })
    training_events = _.orderBy(
      training_events,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_events = training_events
  },
  setTrainingMuscles(state, training_muscles) {
    training_muscles = _.orderBy(
      training_muscles,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_muscles = training_muscles
  },
  setTrainingMachines(state, training_machines) {
    training_machines = _.orderBy(
      training_machines,
      ['sort', 'id'],
      ['asc', 'desc']
    )
    state.training_machines = training_machines
  },
  setTimeZones(state, time_zones) {
    time_zones = _.orderBy(time_zones, ['sort', 'id'], ['asc', 'desc'])
    state.time_zones = time_zones
  },
  setSurveys(state, surveys) {
    surveys = _.orderBy(surveys, ['sort', 'id'], ['asc', 'desc'])
    state.surveys = surveys
  },  
  setReserveSettings(state, reserve_settings) {
    reserve_settings = _.orderBy(reserve_settings, ['sort', 'id'], ['asc', 'desc'])
    state.reserve_settings = reserve_settings
  },
  setCompanyGroups(state, company_groups) {
    company_groups = _.orderBy(company_groups, ['sort', 'id'], ['asc', 'desc'])
    state.company_groups = company_groups
  },
  setAllCalendars(state, all_calendars) {
    all_calendars = _.orderBy(all_calendars, ['sort', 'id'], ['asc', 'desc'])
    state.all_calendars = all_calendars
  },
  setAllResources(state, all_resources) {
    all_resources = _.orderBy(all_resources, ['sort', 'id'], ['asc', 'desc'])
    state.all_resources = all_resources
  },
  setClient(state, client) {
    console.log('setClient')
    state.selectedClient = client

    // リスト追加
    let new_clients = _.filter(state.latest_clients, cli => {
      return cli.id !== client.id
    })
    new_clients.unshift(client)
    // 20超えたら銭湯削除
    if (new_clients.length > 10) new_clients.splice(-1)
    state.latest_clients = new_clients
  },
  clearLatestClients(state) {
    state.latest_clients = []
  },
  setCalendarIds(state, calendar_ids) {
    state.calendar_ids = calendar_ids
  },
  setCalendarViewType(state, calendar_view_type) {
    state.calendar_view_type = calendar_view_type
  },
  setCalendarOnlyBusinessHours(state, calendar_only_business_hours) {
    state.calendar_only_business_hours = calendar_only_business_hours
  },
  setCalendarShowExtraDays(state, calendar_show_extra_days) {
    state.calendar_show_extra_days = calendar_show_extra_days
  },
  setCalendarStartOnSunday(state, calendar_start_on_sunday) {
    state.calendar_start_on_sunday = calendar_start_on_sunday
  },
  setCalendarResourceMode(state, calendar_resource_mode) {
    state.calendar_resource_mode = calendar_resource_mode
  },
  setCalendarDuration(state, calendar_duration) {
    state.calendar_duration = calendar_duration
  },
  setReverveCheckHasTicket(state, reverve_check_has_ticket) {
    state.reverve_check_has_ticket = reverve_check_has_ticket
  },
  setReservePush(state, reserve_push) {
    state.reserve_push = reserve_push
  },
  setShowEventSetDetail(state, showEventSetDetail) {
    state.showEventSetDetail = showEventSetDetail
  },
  setClientLists(state, clientLists) {
    state.clientLists = clientLists
  }
}
export const getters = {}
export const actions = {
  async getCompanies(context) {
    await httpClient
      .get('/cmp/account/all_companies.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setCompanies', res.data.data.companies)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },
  async getStaffs(context) {
    await httpClient
      .get('/cmp/account/staffs.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setStaffs', res.data.data.staffs)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },
  async getClient(context, uid) {
    await httpClient
      .get(`/cmp/floor/clients/${uid}.json`)
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setClient', res.data.data.client)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  },
  async getCalendars(context) {
    await httpClient
      .get('/cmp/floor/calendars.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setCalendars', res.data.data.calendars)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getResources(context) {
    await httpClient
      .get('/cmp/floor/resources.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setResources', res.data.data.resources)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getCourses(context) {
    await httpClient
      .get('/cmp/floor/courses.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setCourses', res.data.data.courses)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getMenus(context) {
    await httpClient
      .get('/cmp/floor/menus.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setMenus', res.data.data.menus)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getSections(context) {
    await httpClient
      .get('/cmp/floor/sections.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setSections', res.data.data.sections)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getPaymentMethods(context) {
    await httpClient
      .get('/cmp/floor/payment_methods.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setPaymentMethods', res.data.data.payment_methods)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getItemCategories(context) {
    await httpClient
      .get('/cmp/floor/item_categories.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setItemCategories', res.data.data.item_categories)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getItems(context) {
    await httpClient
      .get('/cmp/floor/items.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setItems', res.data.data.items)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getTrainingCategories(context) {
    await httpClient
      .get('/cmp/floor/training_categories.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit(
            'setTrainingCategories',
            res.data.data.training_categories
          )
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getTrainingEvents(context) {
    await httpClient
      .get('/cmp/floor/training_events.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setTrainingEvents', res.data.data.training_events)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getTrainingMachines(context) {
    await httpClient
      .get('/cmp/floor/training_machines.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setTrainingMachines', res.data.data.training_machines)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getTrainingMuscles(context) {
    await httpClient
      .get('/cmp/floor/training_muscles.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setTrainingMuscles', res.data.data.training_muscles)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getTimeZones(context) {
    await httpClient
      .get('/cmp/floor/time_zones.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setTimeZones', res.data.data.time_zones)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getSurveys(context) {
    await httpClient
      .get('/cmp/floor/surveys.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setSurveys', res.data.data.surveys)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getReserveSettings(context) {
    await httpClient
      .get('/cmp/floor/reserve_settings.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setReserveSettings', res.data.data.reserve_settings)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getCompanyGroups(context) {
    await httpClient
      .get('/cmp/floor/company_groups.json')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setCompanyGroups', res.data.data.company_groups)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getAllCalendars(context) {
    await httpClient
      .get('/cmp/floor/calendars.json?get_all=true')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setAllCalendars', res.data.data.calendars)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
  async getAllResources(context) {
    await httpClient
      .get('/cmp/floor/resources.json?get_all=true')
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setAllResources', res.data.data.resources)
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {})
  },
}
