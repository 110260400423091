import _ from 'lodash'
import { sleep } from '~/utils/tool'

const getDefaultState = () => {
  return {
    fireStoreObserver: null,
    data: null
  }
}

export const state = () => getDefaultState()
export const mutations = {
  resetAll(state) {
    Object.assign(state, getDefaultState())
  },
  // contact
  setFireStoreObserver(state, fireStoreObserver) {
    state.fireStoreObserver = fireStoreObserver
  },
  setData(state, data) {
    state.data = data
  }
}
export const actions = {
  // FireStoreへの接続
  // アカウント切り替え時は強制的に行う
  async init(context, force = false) {
    if (force === false && window.storeCtl.state.auth.authToken === null) return
    if (force === false && window.storeCtl.state.fireStore.fireStoreObserver)
      return

    // データの監視
    // console.log("firestore init",window.storeCtl.state.auth.company.uid)
    try {
      const doc = this.$fire.firestore
        .collection('companies')
        .doc(window.storeCtl.state.auth.company.uid)
      let fireStoreObserver = doc.onSnapshot(
        async docSnapshot => {
          let data = docSnapshot.data()
          window.storeCtl.commit('fireStore/setData', data)
          console.log(`Received doc snapshot:`, data)
        },
        err => {
          console.log(`Encountered error: ${err}`)
        }
      )
      context.commit('setFireStoreObserver', fireStoreObserver)
    } catch (e) {
      console.log('no firestore document')
      const doc = this.$fire.firestore
        .collection('companies')
        .doc(window.storeCtl.state.auth.company.uid)
      this.$fire.firestore
        .collection('companies')
        .doc(window.storeCtl.state.auth.company.uid)
        .set(
          {
            action_type: 'all',
            memo: 'init'
          },
          { merge: true }
        )
        .then(response => {
          // 更新成功時の処理
          console.log('firestore init setup', response)
        })
        .catch(error => {
          // 更新失敗時の処理
        })
    }
  },

  //// チャネルの購読をとく
  destroy(context) {
    if (window.storeCtl.state.fireStore.fireStoreObserver === null) return
    window.storeCtl.state.fireStore.fireStoreObserver()
    context.commit('resetAll')
  }
}
