
import dayjs from 'dayjs';

export default {
  components: {
  },
  props: {
    client: Object,
    relatedLessons: Array,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    hasAlert(client){
      if(client.next_lesson_alert_date && dayjs(client.next_lesson_alert_date).isBefore(dayjs())){
        return true
      }else{
        return false
      }
    },
    alertMessage(client){
      let prev_lesson = this.selectLesson(client.prev_lesson_id)
      if(prev_lesson === null) return
      let day_size = dayjs().diff(dayjs(prev_lesson.date), "d")
      return `${day_size}日経過` 
    },
    selectLesson(lesson_id){
      return _.find(this.relatedLessons, ["id", lesson_id])
    },
  },
}
