
import Common from '~/mixins/common'
import _ from 'lodash';
import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showEditMemoModal: false,
      current_client: null,
      isInner: false,
    }
  },
  async mounted() {
    let self = this
    this.current_client = _.cloneDeep(self.client)
  },
  watch: {
    client(val){
      this.current_client = _.cloneDeep(val)
    },
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    editMemo(client){
      if(client) this.current_client = _.cloneDeep(client)
      this.isInner = false
      this.showEditMemoModal = true
    },
    editInnerMemo(client){
      if(client) this.current_client = _.cloneDeep(client)
      this.isInner = true
      this.showEditMemoModal = true
    },
    async updateMemo(){
      let self = this
      self.$store.commit("loading/start")
      let data = null
      if(this.isInner){
        data = {
          key: "inner_memo",
          value: self.current_client.inner_memo,
        }
      }else{
        data = {
          key: "memo",
          value: self.current_client.memo,
        }
      }
      await httpClient
        .put(`/cmp/floor/clients/${self.current_client.uid}/update_by_key.json`, data)
        .then(async (res) => {
          if (res.data.status === 'success') {
            window.storeCtl.commit("loading/stop")
            this.showEditMemoModal = false
            self.$emit("updated", res.data.data.client)
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
