
import { mapState } from 'vuex'
import Alert from '~/components/util/Alert'
import Loading from '~/components/util/Loading'
import ConfirmDialog from '~/components/util/ConfirmDialog'

export default {
  name: 'default',
  components: {
    Alert,
    Loading,
    ConfirmDialog
  },
  // mixins: [Meta],
  // async asyncData({ error, params, store }) {
  // },
  data() {
    return {}
  },
  async mounted() {
    let self = this
    self.$nuxt.$root.$confirm = this.$refs.confirm
  },
  computed: {
    ...mapState({})
  },
  methods: {}
}
