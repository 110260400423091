
import { Line } from 'vue-chartjs'
// import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation'
import _ from 'lodash';
import dayjs from 'dayjs';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  extends: Line,
  props: {
    client: Object,
    client_trends: Array,
  },
  components: {
    // ChartJsPluginDataLabels,
  },
  computed: {
  },
  watch: {
    client_trends: function (client_trends) {
      // console.log("trends watch",trends)
      this.updateChart(client_trends)
    },
  },
  mounted() {
    this.addPlugin(chartjsPluginAnnotation)
    this.updateChart(this.client_trends);
  },
  data: function () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            yAxisID: "fat_rate",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: '体脂肪率',
            backgroundColor: '#DC05F5',
            borderColor: '#DC05F5',
            fill: false,
            data: [],
            spanGaps: true,
          },
          {
            yAxisID: "weight",
            lineTension: 0,
            type: 'line',
            label: '体重',
            backgroundColor: '#2614F7',
            borderColor: '#2614F7',
            fill: false,
            data: [],
            spanGaps: true,
          },
        ]
      },
      options: {
        legend : {
          // display : false,
          position: "bottom", 
          labels: {
            fontSize: 10,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { /* ここです */
          callbacks: {
            label: function(tooltipItem, data){
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '日付'
            },
            ticks : {
              minRotation: 0,
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 4,
              callback: function(tick, index) {
                let str = dayjs(tick).format("YY/MM/DD");
                // let isRetern = false
                // if(index === 0) isRetern = true
                // if((index + 1) % 5 === 0) isRetern = true
                // return isRetern ? str : "";
                return str
              }
            }
          }],
          yAxes: [
            {
              id: "fat_rate",
              type: "linear",
              position: "left",
              ticks: {
                // min: 10,
                // max: 20,
                beginAtZero: false,
                maxTicksLimit: 20,
                stepSize: 0.5,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "%" : ''
                }
              }
            },
            {
              id: "weight",
              type: "linear",
              position: "right",
              gridLines: {
                //y軸の網線
                display: false //表示するか否か
              },
              // https://tr.you84815.space/chartjs/axis/cartesian/linear.html
              ticks: {
                // min: 45,
                // max: 55,
                beginAtZero: false,
                maxTicksLimit: 20,
                stepSize: 0.5,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "kg" : ''
                }
              }
            },
          ]
        },
        // 横線
        annotation: {
          annotations: [
            {
              type: 'line', // 線分を指定
              drawTime: 'afterDatasetsDraw',
              id: 'a-line-1', // 線のid名を指定（他の線と区別するため）
              mode: 'horizontal', // 水平を指定
              scaleID: 'fat_rate', // 基準とする軸のid名
              value: 15.6, // 引きたい線の数値（始点）
              endValue: 15.6, // 引きたい線の数値（終点）
              borderColor: '#DC05F5', // 線の色
              borderWidth: 3, // 線の幅（太さ）
              borderDash: [2, 2],
              borderDashOffset: 1,
              label: { // ラベルの設定
                backgroundColor: 'rgba(255,255,255,0.5)',
                bordercolor: '#DC05F5',
                borderwidth: 2,
                fontSize: 10,
                fontStyle: 'bold',
                fontColor: '#DC05F5',
                xPadding: 10,
                yPadding: 10,
                cornerRadius: 3,
                position: 'left',
                xAdjust: 0,
                yAdjust: 0,
                enabled: true,
                content: '目標体脂肪率'
              }
            },
            {
              type: 'line', // 線分を指定
              drawTime: 'afterDatasetsDraw',
              id: 'a-line-2', // 線のid名を指定（他の線と区別するため）
              mode: 'horizontal', // 水平を指定
              scaleID: 'weight', // 基準とする軸のid名
              value: 60, // 引きたい線の数値（始点）
              endValue: 60, // 引きたい線の数値（終点）
              borderColor: '#2614F7', // 線の色
              borderWidth: 3, // 線の幅（太さ）
              borderDash: [2, 2],
              borderDashOffset: 1,
              label: { // ラベルの設定
                backgroundColor: 'rgba(255,255,255,0.5)',
                bordercolor: '#2614F7',
                borderwidth: 2,
                fontSize: 10,
                fontStyle: 'bold',
                fontColor: '#2614F7',
                xPadding: 10,
                yPadding: 10,
                cornerRadius: 3,
                position: 'left',
                xAdjust: 0,
                yAdjust: 0,
                enabled: true,
                content: '目標体重'
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    updateChart(client_trends){
      console.log("trends",client_trends)
      client_trends = _.orderBy(client_trends, 'date', 'asc');
      // データ加工
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      this.chartdata.datasets[1].data = []
      client_trends.forEach(tr => {
        this.chartdata.labels.push(tr.date)
        this.chartdata.datasets[0].data.push(tr.fat_rate)
        this.chartdata.datasets[1].data.push(tr.weight)
      })
      // 目標線
      if(this.company.use_goal && this.client.goal_fat_rate){
        this.options.annotation.annotations[0].scaleID = "fat_rate"
        this.options.annotation.annotations[0].value = this.client.goal_fat_rate
        this.options.annotation.annotations[0].endValue = this.client.goal_fat_rate
      }else{
        this.options.annotation.annotations[0].scaleID = "fat_rate_none"
      }
      if(this.company.use_goal && this.client.goal_weight){
        this.options.annotation.annotations[1].scaleID = "weight"
        this.options.annotation.annotations[1].value = this.client.goal_weight
        this.options.annotation.annotations[1].endValue = this.client.goal_weight
      }else{
        this.options.annotation.annotations[1].scaleID = "weight_none"
      } 

      // 設定
      this.options.scales.xAxes[0].ticks.maxTicksLimit = this.$store.state.devise.isPC ? 10 : 4

      this.renderChart(this.chartdata, this.options)

    },
  }
}
