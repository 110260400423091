import _ from 'lodash'
import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    devise_token: null,
    isPC: true,
    isIPad: false,
    isTouchDevice: false,
    drawer: false,
    showBorad: true,

    // 各種確認お知らせ
    firstContactDone: false,
    showFirstContactModal: false,
    first_to_client_review: true,
    first_to_lesson_list_review: true,

    // table表示設定
    client_list_mode: 'table',
    client_list_pager: 25,
    able_client_table_field_keys: [
      "name","lesson","inner_memo","memo",
      "reserve_type_text","able_menu_ids",
      "use_subscriptions","date",
    ],

    // 複数アカウントログイン用
    // {company_uid: null, company_name: "A店", staff_uid: 1, staff_name: "山田", authToken: "aaaa", calendar_ids},
    authLists: [],
    able_companies: [],

    // sales_data
    sales_data_tabIndex: 0,
    sales_data_termType: 'daily',
    sales_data_targetMonth: dayjs().format('YYYY-MM'),
    sales_data_targetYear: dayjs().format('YYYY'),
    showClientCountOld: false,

    // トレーニング入力
    multiple_select_trainig_event: false,
  }
}

export const state = () => getDefaultState()
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  // デバイス操作
  setDeviseToken(state, devise_token) {
    state.devise_token = devise_token
  },
  setIsPC(state, boolean) {
    state.isPC = boolean
  },
  setIsIPad(state, boolean) {
    state.isIPad = boolean
    state.isTouchDevice = boolean
  },
  setIsTouchDevice(state, boolean) {
    if (state.isIPad) {
      state.isTouchDevice = true
    } else {
      state.isTouchDevice = boolean
    }
  },
  setDrawer(state, drawer) {
    state.drawer = drawer
  },
  changeShowBorad(state) {
    state.showBorad = !state.showBorad
  },

  // alert
  firstContactDone(state) {
    state.firstContactDone = true
  },
  setFirstContactModal(state, payload) {
    state.showFirstContactModal = payload
  },
  clearAlert(state, label) {
    eval(`state.${label} = false`)
  },

  // table
  setClientListMode(state, client_list_mode) {
    state.client_list_mode = client_list_mode
  },
  setClientListPager(state, client_list_pager) {
    state.client_list_pager = client_list_pager
  },
  setAbleClientTableFieldKeys(state, able_client_table_field_keys) {
    state.able_client_table_field_keys = able_client_table_field_keys
  },

  //sales_data
  setSalesDataSettings(state, payload) {
    state.sales_data_tabIndex = payload.tabIndex
    state.sales_data_termType = payload.termType
    state.sales_data_targetMonth = payload.targetMonth
    state.sales_data_targetYear = payload.targetYear
  },
  setShowClientCountOld(state, value) {
    state.showClientCountOld = value
  },

  // 多重ログイン
  addAuthList(state, account) {
    let authLists = []
    let isExist = false
    state.authLists.forEach(auth => {
      if (auth.staff_uid === account.staff_uid) {
        authLists.push(account)
        isExist = true
      } else {
        authLists.push(auth)
      }
    })
    if (!isExist) authLists.push(account)
    state.authLists = authLists
  },
  updateAuthList(state, account) {
    let authLists = []
    state.authLists.forEach(auth => {
      if (auth.staff_uid === account.staff_uid) {
        authLists.push(account)
      } else {
        authLists.push(auth)
      }
    })
    state.authLists = authLists
  },
  deleteAuthList(state, account) {
    state.authLists = _.filter(state.authLists, auth => {
      return auth.staff_uid !== account.staff_uid
    })
  },

  // 店舗切り替え
  setAbleCompanies(state, able_companies) {
    state.able_companies = able_companies
  },

  setMultipleSelectTrainigEvent(state, multiple_select_trainig_event){
    state.multiple_select_trainig_event = multiple_select_trainig_event
  },
}
