
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import _ from 'lodash';
import ImageFileInput from '~/components/util/ImageFileInput'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import VideoInputs from '~/components/util/videos/VideoInputs'
import { training_event, form_config } from '~/utils/models'
import Rating from '~/components/util/Rating'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    VideoInputs,
    ImageFileInput,
    Rating,
  },
  props: {
  },
  data() {
    return {
      // edit
      modalTitle: null,
      edit_mode: null,
      edit_training_event: null,
      pofTypeOptions: [
        { text: 'ミッドレンジ', value: "mid" },
        { text: 'コントラクト', value: "contract" },
        { text: 'ストレッチ', value: "stretch" },
        { text: 'その他', value: "other" },
      ],

      // modal
      showTrainingEventEditModal: false,

      img_icon: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    this_docs() {
      let docs = []
      this.edit_training_event.media.doc_ids.forEach(doc_id => {
        let doc = _.find(this.edit_training_event.docs, ["id", doc_id])
        if(doc) docs.push(doc)
      })
      return docs
    },
    this_videos() {
      return _.filter(this.edit_training_event.videos, (d) => {return this.edit_training_event.media.video_ids.includes(d.id)})
    },
  },
  methods: {
    // ファイル------
    docsUpdated(new_docs){
      // console.log("docsUpdated", new_docs)
      this.edit_training_event.docs = new_docs
      this.edit_training_event.media.doc_ids = _.map(new_docs, "id")
    },
    videosUpdated(new_videos){
      // console.log("docsUpdated", new_docs)
      this.edit_training_event.videos = new_videos
      this.edit_training_event.media.video_ids = _.map(new_videos, "id")
    },

    // item
    newTrainingEvent(category){
      this.edit_mode = "create"
      this.modalTitle = "種目新規作成"
      this.edit_training_event = _.cloneDeep(training_event)
      if(category) this.edit_training_event.training_category_id = category.id
      this.showTrainingEventEditModal = true
    },
    editTrainingEvent(training_event){
      this.edit_mode = "update"
      this.modalTitle = _.cloneDeep(training_event.name)
      this.edit_training_event = _.cloneDeep(training_event)
      this.showTrainingEventEditModal = true
    },
    async createTrainingEvent(){
      let self = this

      // form
      const formData = new FormData();
      // icon
      if(self.img_icon){
        formData.append("img_icon", self.img_icon)
      }else{
        // 画像削除
        if(!self.edit_training_event.img_icon) formData.append("img_icon", "null")    
      }
      // other
      let training_event_obj = JSON.stringify(self.edit_training_event)
      formData.append('training_event_obj', training_event_obj)

      self.$store.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/training_events.json`, formData, form_config)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.training_event)
            window.storeCtl.commit("loading/stop")
            self.showTrainingEventEditModal = false
            window.storeCtl.commit("alert/setSuccess", "作成しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    async updateTrainingEvent(){
      let self = this

      // form
      const formData = new FormData();
      // icon
      if(self.img_icon){
        formData.append("img_icon", self.img_icon)
      }else{
        // 画像削除
        if(!self.edit_training_event.img_icon) formData.append("img_icon", "null")    
      }
      // other
      let training_event_obj = JSON.stringify(self.edit_training_event)
      formData.append('training_event_obj', training_event_obj)

      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/training_events/${self.edit_training_event.uid}.json`, formData, form_config)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.training_event)
            window.storeCtl.commit("loading/stop")
            self.showTrainingEventEditModal = false
            window.storeCtl.commit("alert/setSuccess", "更新しました")
          } else {
            window.storeCtl.commit("loading/stop")
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    deleteTrainingEvent(training_event){
      let self = this
      self.$root.$confirm.open('確認', '削除してよろしいですか？', { color: 'warning' }).then(async (confirm) => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .delete(`/cmp/floor/training_events/${training_event.uid}.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                self.$emit("updated")
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setSuccess", "削除しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
  }
}
