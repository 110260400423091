
import _ from 'lodash';
import { mapState } from 'vuex'
import Common from '~/mixins/common'
import { httpClient } from '~/utils/axios'
import UserIcon from '~/components/util/UserIcon'
import ReportMenu from '~/components/common/report/Menu'
import Compressor from 'compressorjs'

let new_chat = {
  message: null,
  reply_chat_msg_id: null,
  settings: {
    reply_chat_msg: null,
    hasReaction: false,
    reactions: [
      {name: "thumbup", text: "いいね！", users: []},
      {name: "thanks", text: "ありがとう！", users: []},
      {name: "confirm", text: "確認します！", users: []},
      {name: "ryokai", text: "了解です！", users: []},
      {name: "party", text: "おめでとう！", users: []},
      {name: "sorry", text: "悲しい", users: []},
      {name: "apologize", text: "すいませんｍ", users: []},
      {name: "fight", text: "頑張って！", users: []},
    ],
    docs: [],
  },
}

export default {
  mixins: [Common],
  components: {
    UserIcon,
    ReportMenu,
  },
  props: {
    chat_room: Object,
  },
  data() {
    return {
      loading: false,
      new_chat: _.cloneDeep(new_chat),

      // docs
      progre: 0,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    clear(){
      this.new_chat = _.cloneDeep(new_chat)
      if(!this.isTouchDevice) this.$refs.input_form.focus()
    },
    chatReply(from_chat){
      this.new_chat.reply_chat_msg_id = from_chat.id
      this.new_chat.settings.reply_chat_msg = from_chat
      if(!this.isTouchDevice) this.$refs.input_form.focus()
    },
    chatQuote(from_chat){
      let old_mstrs = from_chat.message.split(/\n/)
      if(this.new_chat.message !== null){
        this.new_chat.message += "\n"
      }else{
        this.new_chat.message = ""
      }
      old_mstrs.forEach(mstr => {
        this.new_chat.message += `> ${mstr}\n`
      })
      this.new_chat.message += "\n"
      setTimeout(()=>{
        this.$refs.input_form.focus()
      }, 1000 * 0.1)
    },

    // image ---------------------------------
    async setFile(event) {
      let self = this
      // 容量チェック
      let hasSizeOver = false
      for(let i = 0; i < event.target.files.length; i++){
        const file = event.target.files[i]
        if(file.size > 1000000 * 10) hasSizeOver = true
      }
      if(hasSizeOver){
        window.storeCtl.commit("alert/setError", "ファイルサイズは10MB以下にしてください")
        self.files = []
        return 
      }
      self.loading = true
      //　アップロード処理
      let promiseList = []
      for(let i = 0; i < event.target.files.length; i++){
        const file = event.target.files[i]
        promiseList.push(this.upload(file))     
      }
      await Promise.all(promiseList)
      self.loading = false
    },
    async upload(file){
      let self = this
      const formData = new FormData();
      if(self.chat_room_id) formData.append(`doc[chat_room_id]`, self.chat_room.id)
      formData.append(`doc[doc_type]`, "chat")
      formData.append(`doc[origin_file_name]`, file.name)
      formData.append(`doc[file_name]`, file.name)
      // file type
      let file_type = "other"
      if (file.type === "application/pdf") file_type = "pdf"
      if (file.type.includes("image/")) file_type = "img"
      if (file.type.includes("video/")) file_type = "video"
      if (file.type.includes("audio/")) file_type = "audio"
      formData.append(`doc[file_type]`, file_type)
      // ファイルと画像で変える
      if(file.type.includes("image/")){
        let baseFile = file
        if (file.type === 'image/heif' || file.type === 'image/heic'){
          baseFile = await heic2any({
            blob: file,
            toType: 'image/jpeg',
          });
        }
        const newFile = await new Promise((resolve, reject) => {
          new Compressor(baseFile, {
            quality: 0.7,
            maxWidth: 1500,
            maxHeight: 1500,
            success: resolve,
            error: reject,
          });
        });
        formData.append(`doc[img]`, newFile)
      }else{
        formData.append(`doc[file]`, file)
      }
      await httpClient
        .post(`/cmp/floor/docs.json`, formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
            onUploadProgress: e => {
              self.progre = parseInt(e.loaded / e.total * 100);
              console.log(`進捗`, self.progre)
            }
          }
        )
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.new_chat.settings.docs.push(res.data.data.doc)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    deleteFile(doc){
      console.log("deleteFile",doc)
      let self = this
      self.new_chat.settings.docs = _.remove(self.new_chat.settings.docs, (d) => {return d.uid !== doc.uid})
    },

    async sendMmessageByKey(e){
      if (e.ctrlKey || e.metaKey) this.sendMessage()
    },
    async sendMessage(){
      let self = this
      self.loading = true
      await httpClient
        .post(`/cmp/floor/chat_msgs.json`, {
          chat_room_uid: self.chat_room.uid,
          message: self.new_chat.message,
          reply_chat_msg_id: self.new_chat.reply_chat_msg_id,
          settings: self.new_chat.settings,
        })
        .then(async (res) => {
          self.loading = false
          if (res.data.status === 'success') {
            self.$emit("sent", res.data.data.chat_msg)
            
            setTimeout(()=>{
              self.clear()
            }, 100)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          self.loading = false
        })
    },
  },
}
