import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import { training_event_status } from '~/utils/models'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  data() {
    return {}
  },
  async mounted() {},
  computed: {},
  filters: {},
  methods: {
    // 該当の種目データ取得
    currentTrainingEvent(event_set) {
      let training_event = _.find(this.training_events, [
        'uid',
        event_set.event_uid
      ])
      if (training_event) {
        return training_event
      } else {
        return event_set.event
      }
    },
    training_status_text(status) {
      return _.find(training_event_status, ['value', status]).text
    }
  }
}
