
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream
  },
  props: {
    title: String,
  },
  data() {
    return {
      showModal: false,
      result: '',
      error: '',
      selectedDeviceId: null,
      current_devices: [],
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    async open(){
      // this.current_devices = []
      // let devices = ().filter(
      //   ({ kind }) => kind === 'videoinput'
      // )
      // devices.forEach(d => {
      //   this.current_devices.push({
      //     deviceId: d.deviceId,
      //     label: d.label,
      //   })
      // })
      // if (this.current_devices.length > 0) {
      //   this.selectedDeviceId = this.current_devices[0].deviceId
      // }
      this.showModal = true
    },
    onDecode (result) {
      this.$emit("onDecoded", result)
      this.showModal = false
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    },
  },
}
