
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { scrollToTop } from '~/utils/tool'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: {type: Object, default: null},
  },
  data() {
    return {
      survey_answers: [],
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
    }
  },
  async mounted() {
    let self = this
    this.clear()
  },
  computed: {
  },
  methods: {
    async clear() {
      this.freeword = null
      this.use_type_eq_any = []
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.order = "desc"
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this
      // set params
      let params = {
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/surveys/list_of_client.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          this.survey_answers = res.data.data.survey_answers
          self.pages = res.data.data.pages
          scrollToTop()
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },

    ansserText(answer_detail){
      try{
        if(answer_detail.survey_question.style === "checkbox"){
          return JSON.parse(answer_detail.choices).join(",")
        }else{
          return answer_detail.text
        }
      }catch(e){
        return null
      }
    },
  }
}
