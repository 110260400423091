
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import _ from 'lodash';
import { scrollToTop } from '~/utils/tool'
import LessonTrainingEvents from '~/components/common/lesson/TrainingEvents'
import TrainingListEdit from '~/components/common/training_list/Edit'
import Rating from '~/components/util/Rating'

export default {
  mixins: [Common],
  components: {
    LessonTrainingEvents,
    TrainingListEdit,
    Rating,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      // data
      data_expand: false,
      only_open: false,
      only_client: false,
      currentTrainingLists: [],
      freeword: null,
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
    }
  },
  async mounted() {
    this.autoSearch()
  },
  watch: {
  },  
  computed: {
  },
  methods: {
    copyTrainingList(training_list){
      this.$refs.training_list_edit.newTrainingList(training_list)
    },
    deleteTrainingList(training_list){
      let self = this
      self.$root.$confirm.open('確認', '削除してよろしいですか？', { color: 'warning' }).then(async (confirm) => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .delete(`/cmp/floor/training_lists/${training_list.uid}.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                await self.autoSearch()
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setSuccess", "削除しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    async clear() {
      this.freeword = null
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search (scrolleToTop = true) {
      let self = this
      let url = `/cmp/floor/training_lists.json?page=${self.pages.current_page}`
      if(self.client) url = url + `&client_id=${self.client.id}`
      if(self.only_open) url = url + `&only_open=true`
      if(self.only_client) url = url + `&only_client=true`
      if(self.freeword) url = url + `&q[uid_or_name_or_description_or_client_uid_or_client_name_cont]=${self.freeword}`
      window.storeCtl.commit("loading/start")
      await httpClient
        .get(url)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.currentTrainingLists = res.data.data.training_lists
            self.pages = res.data.data.pages
            if(scrolleToTop){
              scrollToTop()
            }
          } else {
            self.$store.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
  }
}
