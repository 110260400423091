
import { mapState } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      snackbar: false,
    }
  },
  async mounted() {
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
    }),
    time() {
      return this.$store.getters['alert/getTime']; // OK
    },
  },
  watch: {
    time (val, old) {
      this.snackbar = true
    }
  },
  methods: {
  },
}
