
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import Common from '~/mixins/common'
import dayjs from 'dayjs';

export default {
  mixins: [Common],
  components: {
  },
  props: {
    chat: Object,
    type: String,
    isEdit: {type: Boolean, default: false}
  },
  data() {
    return {
      current_chat: null,
      loading: false,
    }
  },
  async mounted() {
    let self = this
    self.current_chat = this.chat
  },
  watch: {
    chat(obj){
      this.current_chat = _.cloneDeep(obj)
    },
  },
  computed: {
    currentReaction(){
      if(this.current_chat){
        return _.find(this.current_chat.settings.reactions, ["name", this.type])
      }else{
        return {name: "-", text: "-", users: []}
      }
    },
    isExact(){
      return _.find(this.currentReaction.users, ["user_uid", this.staff.uid])
    },
  },
  methods: {
    async reAction(){
      let self = this
      // if(!self.isEdit) return
      let addOrRemove = self.isExact ? "remove" : "add"

      // リアクションを新しく
      let new_reactions = []
      self.current_chat.settings.reactions.forEach(ra => {
        if(ra.name === self.type){
          if(self.isExact){
            // 削除
            ra.users = _.remove(ra.users, (u) => { return u.uid === self.staff.uid });
          }else{
            // 追加
            ra.users.push({
              uid: self.generateUUID(),
              user_type: "staff",
              user_uid: self.staff.uid,
              user_name: self.staff.name,
              time: dayjs().format("YYYY/MM/DD HH:mm:ss")
            })
          }
        }
        new_reactions.push(ra)
      })

      self.current_chat.settings.reactions = new_reactions 
      self.current_chat.settings.hasReaction = true 

      self.loading = true
      await httpClient
        .put(`/cmp/floor/chat_msgs/${self.current_chat.uid}/reaction.json`, {
          reaction_type: self.type,
          action_type: addOrRemove,
          chat_msg: self.current_chat,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.chat_msg)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          self.loading = false
          window.storeCtl.commit("loading/stop")
        })
    },
  },
}
