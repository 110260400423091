
import _ from 'lodash';
import Common from '~/mixins/common'
import GoalTermEdit from '~/components/common/goal_term/Edit'
import GoalTermStatusChip from '~/components/common/goal_term/StatusChip'
import GoalTermDiffPart from '~/components/common/goal_term/DiffPart'

export default {
  mixins: [Common],
  components: {
    GoalTermEdit,
    GoalTermStatusChip,
    GoalTermDiffPart,
  },
  props: {
    goal_term: Object,
    client: Object,
  },
  data() {
    return {
      selectedImg: "front",
      edit_goal_term: null,
    }
  },
  async mounted() {
    this.edit_goal_term = _.cloneDeep(this.goal_term)
  },
  watch: {
    goal_term(val){
      console.log("goal_term watch")
      this.edit_goal_term = _.cloneDeep(val)
    },
  },
  computed: {
  },
  methods: {
    clickImg(type){
      this.selectedImg = type
    },
    updated(event){
      this.edit_goal_term = _.cloneDeep(event)
    },
  }
}
