
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    color: String,
  },
  data() {
    return {
      init_color: null,
      edit_color: null,
      modal_init: false,
      editing: false,
      swatches: [
        ['#D50000', '#FF5722', '#FFD600', '#4CAF50', '#1E88E5', '#8E24AA'],
        ['#C2185B', '#FF7043', '#DCE775', '#4DB6AC', '#42A5F5', '#6D4C41'],
        ['#E53935', '#F57C00', '#9CCC65', '#26A69A', '#64B5F6', '#757575'],
        ['#F06292', '#FBC02D', '#81C784', '#42A5F5', '#AB47BC', '#BDBDBD']
      ],
    }
  },
  async mounted() {
    this.init_color = this.color
    this.edit_color = this.color
  },
  watch: {
    color(val){
      this.init_color = val
      this.edit_color = val
    },
  },
  computed: {
  },
  methods: {
    openModal(){
      this.editing = true
    },
    update_color(e){
      this.edit_color = e.hexa
      if(this.modal_init) this.set()
      this.modal_init = true
    },
    reset(){
      this.editing = false
    },
    set(){
      this.init_color = this.edit_color
      this.editing = false
      this.modal_init = false
      this.$emit("change", this.edit_color)
    },
  },
}
