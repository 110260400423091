import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7adf747a = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _3efa2c94 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _7e388362 = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _5c3c6b43 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _462bd939 = () => interopDefault(import('../pages/sales_data/index.vue' /* webpackChunkName: "pages/sales_data/index" */))
const _63455073 = () => interopDefault(import('../pages/surveys/index.vue' /* webpackChunkName: "pages/surveys/index" */))
const _59987aaa = () => interopDefault(import('../pages/login/deputy.vue' /* webpackChunkName: "pages/login/deputy" */))
const _bf4d15c4 = () => interopDefault(import('../pages/login/forget_id.vue' /* webpackChunkName: "pages/login/forget_id" */))
const _ccdb1f50 = () => interopDefault(import('../pages/login/re_password.vue' /* webpackChunkName: "pages/login/re_password" */))
const _4a43b2ae = () => interopDefault(import('../pages/settings/alert.vue' /* webpackChunkName: "pages/settings/alert" */))
const _41a73a9d = () => interopDefault(import('../pages/settings/alerts/index.vue' /* webpackChunkName: "pages/settings/alerts/index" */))
const _33c7c045 = () => interopDefault(import('../pages/settings/calendars/index.vue' /* webpackChunkName: "pages/settings/calendars/index" */))
const _1eea7b5a = () => interopDefault(import('../pages/settings/card.vue' /* webpackChunkName: "pages/settings/card" */))
const _09857e1c = () => interopDefault(import('../pages/settings/company_groups/index.vue' /* webpackChunkName: "pages/settings/company_groups/index" */))
const _ee118ef0 = () => interopDefault(import('../pages/settings/courses/index.vue' /* webpackChunkName: "pages/settings/courses/index" */))
const _358afe14 = () => interopDefault(import('../pages/settings/data_export.vue' /* webpackChunkName: "pages/settings/data_export" */))
const _9f286e2e = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _63f6c259 = () => interopDefault(import('../pages/settings/headquarter.vue' /* webpackChunkName: "pages/settings/headquarter" */))
const _0b5ffbd0 = () => interopDefault(import('../pages/settings/items/index.vue' /* webpackChunkName: "pages/settings/items/index" */))
const _52118bf8 = () => interopDefault(import('../pages/settings/menus/index.vue' /* webpackChunkName: "pages/settings/menus/index" */))
const _7e10f6de = () => interopDefault(import('../pages/settings/password.vue' /* webpackChunkName: "pages/settings/password" */))
const _d62972ee = () => interopDefault(import('../pages/settings/payment_histories/index.vue' /* webpackChunkName: "pages/settings/payment_histories/index" */))
const _e58eddee = () => interopDefault(import('../pages/settings/payment_methods/index.vue' /* webpackChunkName: "pages/settings/payment_methods/index" */))
const _1e04df36 = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _234b23cc = () => interopDefault(import('../pages/settings/reserve_settings/index.vue' /* webpackChunkName: "pages/settings/reserve_settings/index" */))
const _9326be6e = () => interopDefault(import('../pages/settings/reserve.vue' /* webpackChunkName: "pages/settings/reserve" */))
const _c0f75516 = () => interopDefault(import('../pages/settings/resources/index.vue' /* webpackChunkName: "pages/settings/resources/index" */))
const _17e43298 = () => interopDefault(import('../pages/settings/sections/index.vue' /* webpackChunkName: "pages/settings/sections/index" */))
const _691cb2ce = () => interopDefault(import('../pages/settings/staffs/index.vue' /* webpackChunkName: "pages/settings/staffs/index" */))
const _e4d7c9c0 = () => interopDefault(import('../pages/settings/team.vue' /* webpackChunkName: "pages/settings/team" */))
const _ca9aa14a = () => interopDefault(import('../pages/settings/time_zones/index.vue' /* webpackChunkName: "pages/settings/time_zones/index" */))
const _8aec5724 = () => interopDefault(import('../pages/settings/training_events/index.vue' /* webpackChunkName: "pages/settings/training_events/index" */))
const _c2ec3494 = () => interopDefault(import('../pages/settings/training_lists/index.vue' /* webpackChunkName: "pages/settings/training_lists/index" */))
const _b5d1a3be = () => interopDefault(import('../pages/settings/training_machines/index.vue' /* webpackChunkName: "pages/settings/training_machines/index" */))
const _c7702cfa = () => interopDefault(import('../pages/settings/training_muscles/index.vue' /* webpackChunkName: "pages/settings/training_muscles/index" */))
const _015f3f63 = () => interopDefault(import('../pages/settings/trial.vue' /* webpackChunkName: "pages/settings/trial" */))
const _25bd5196 = () => interopDefault(import('../pages/settings/zoom.vue' /* webpackChunkName: "pages/settings/zoom" */))
const _127a5cae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3f1ad1aa = () => interopDefault(import('../pages/settings/payment_histories/_uid.vue' /* webpackChunkName: "pages/settings/payment_histories/_uid" */))
const _7f7833d8 = () => interopDefault(import('../pages/alerts/_filter.vue' /* webpackChunkName: "pages/alerts/_filter" */))
const _283dd413 = () => interopDefault(import('../pages/bodyscans/_filter.vue' /* webpackChunkName: "pages/bodyscans/_filter" */))
const _7adf16e2 = () => interopDefault(import('../pages/cl_payments/_filter.vue' /* webpackChunkName: "pages/cl_payments/_filter" */))
const _7d29a7c9 = () => interopDefault(import('../pages/client_trends/_filter.vue' /* webpackChunkName: "pages/client_trends/_filter" */))
const _6818d1bc = () => interopDefault(import('../pages/docs/_filter.vue' /* webpackChunkName: "pages/docs/_filter" */))
const _0b981a5a = () => interopDefault(import('../pages/lessons/_filter.vue' /* webpackChunkName: "pages/lessons/_filter" */))
const _14dbdc24 = () => interopDefault(import('../pages/points/_filter.vue' /* webpackChunkName: "pages/points/_filter" */))
const _b6c5ce7a = () => interopDefault(import('../pages/ticket_sets/_filter.vue' /* webpackChunkName: "pages/ticket_sets/_filter" */))
const _5b3219ce = () => interopDefault(import('../pages/videos/_filter.vue' /* webpackChunkName: "pages/videos/_filter" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/calendar/",
    component: _7adf747a,
    pathToRegexpOptions: {"strict":true},
    name: "calendar"
  }, {
    path: "/chat/",
    component: _3efa2c94,
    pathToRegexpOptions: {"strict":true},
    name: "chat"
  }, {
    path: "/clients/",
    component: _7e388362,
    pathToRegexpOptions: {"strict":true},
    name: "clients"
  }, {
    path: "/login/",
    component: _5c3c6b43,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/sales_data/",
    component: _462bd939,
    pathToRegexpOptions: {"strict":true},
    name: "sales_data"
  }, {
    path: "/surveys/",
    component: _63455073,
    pathToRegexpOptions: {"strict":true},
    name: "surveys"
  }, {
    path: "/login/deputy/",
    component: _59987aaa,
    pathToRegexpOptions: {"strict":true},
    name: "login-deputy"
  }, {
    path: "/login/forget_id/",
    component: _bf4d15c4,
    pathToRegexpOptions: {"strict":true},
    name: "login-forget_id"
  }, {
    path: "/login/re_password/",
    component: _ccdb1f50,
    pathToRegexpOptions: {"strict":true},
    name: "login-re_password"
  }, {
    path: "/settings/alert/",
    component: _4a43b2ae,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alert"
  }, {
    path: "/settings/alerts/",
    component: _41a73a9d,
    pathToRegexpOptions: {"strict":true},
    name: "settings-alerts"
  }, {
    path: "/settings/calendars/",
    component: _33c7c045,
    pathToRegexpOptions: {"strict":true},
    name: "settings-calendars"
  }, {
    path: "/settings/card/",
    component: _1eea7b5a,
    pathToRegexpOptions: {"strict":true},
    name: "settings-card"
  }, {
    path: "/settings/company_groups/",
    component: _09857e1c,
    pathToRegexpOptions: {"strict":true},
    name: "settings-company_groups"
  }, {
    path: "/settings/courses/",
    component: _ee118ef0,
    pathToRegexpOptions: {"strict":true},
    name: "settings-courses"
  }, {
    path: "/settings/data_export/",
    component: _358afe14,
    pathToRegexpOptions: {"strict":true},
    name: "settings-data_export"
  }, {
    path: "/settings/email/",
    component: _9f286e2e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email"
  }, {
    path: "/settings/headquarter/",
    component: _63f6c259,
    pathToRegexpOptions: {"strict":true},
    name: "settings-headquarter"
  }, {
    path: "/settings/items/",
    component: _0b5ffbd0,
    pathToRegexpOptions: {"strict":true},
    name: "settings-items"
  }, {
    path: "/settings/menus/",
    component: _52118bf8,
    pathToRegexpOptions: {"strict":true},
    name: "settings-menus"
  }, {
    path: "/settings/password/",
    component: _7e10f6de,
    pathToRegexpOptions: {"strict":true},
    name: "settings-password"
  }, {
    path: "/settings/payment_histories/",
    component: _d62972ee,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_histories"
  }, {
    path: "/settings/payment_methods/",
    component: _e58eddee,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_methods"
  }, {
    path: "/settings/profile/",
    component: _1e04df36,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile"
  }, {
    path: "/settings/reserve_settings/",
    component: _234b23cc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-reserve_settings"
  }, {
    path: "/settings/reserve/",
    component: _9326be6e,
    pathToRegexpOptions: {"strict":true},
    name: "settings-reserve"
  }, {
    path: "/settings/resources/",
    component: _c0f75516,
    pathToRegexpOptions: {"strict":true},
    name: "settings-resources"
  }, {
    path: "/settings/sections/",
    component: _17e43298,
    pathToRegexpOptions: {"strict":true},
    name: "settings-sections"
  }, {
    path: "/settings/staffs/",
    component: _691cb2ce,
    pathToRegexpOptions: {"strict":true},
    name: "settings-staffs"
  }, {
    path: "/settings/team/",
    component: _e4d7c9c0,
    pathToRegexpOptions: {"strict":true},
    name: "settings-team"
  }, {
    path: "/settings/time_zones/",
    component: _ca9aa14a,
    pathToRegexpOptions: {"strict":true},
    name: "settings-time_zones"
  }, {
    path: "/settings/training_events/",
    component: _8aec5724,
    pathToRegexpOptions: {"strict":true},
    name: "settings-training_events"
  }, {
    path: "/settings/training_lists/",
    component: _c2ec3494,
    pathToRegexpOptions: {"strict":true},
    name: "settings-training_lists"
  }, {
    path: "/settings/training_machines/",
    component: _b5d1a3be,
    pathToRegexpOptions: {"strict":true},
    name: "settings-training_machines"
  }, {
    path: "/settings/training_muscles/",
    component: _c7702cfa,
    pathToRegexpOptions: {"strict":true},
    name: "settings-training_muscles"
  }, {
    path: "/settings/trial/",
    component: _015f3f63,
    pathToRegexpOptions: {"strict":true},
    name: "settings-trial"
  }, {
    path: "/settings/zoom/",
    component: _25bd5196,
    pathToRegexpOptions: {"strict":true},
    name: "settings-zoom"
  }, {
    path: "/",
    component: _127a5cae,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/settings/payment_histories/:uid/",
    component: _3f1ad1aa,
    pathToRegexpOptions: {"strict":true},
    name: "settings-payment_histories-uid"
  }, {
    path: "/alerts/:filter?/",
    component: _7f7833d8,
    pathToRegexpOptions: {"strict":true},
    name: "alerts-filter"
  }, {
    path: "/bodyscans/:filter?/",
    component: _283dd413,
    pathToRegexpOptions: {"strict":true},
    name: "bodyscans-filter"
  }, {
    path: "/cl_payments/:filter?/",
    component: _7adf16e2,
    pathToRegexpOptions: {"strict":true},
    name: "cl_payments-filter"
  }, {
    path: "/client_trends/:filter?/",
    component: _7d29a7c9,
    pathToRegexpOptions: {"strict":true},
    name: "client_trends-filter"
  }, {
    path: "/docs/:filter?/",
    component: _6818d1bc,
    pathToRegexpOptions: {"strict":true},
    name: "docs-filter"
  }, {
    path: "/lessons/:filter?/",
    component: _0b981a5a,
    pathToRegexpOptions: {"strict":true},
    name: "lessons-filter"
  }, {
    path: "/points/:filter?/",
    component: _14dbdc24,
    pathToRegexpOptions: {"strict":true},
    name: "points-filter"
  }, {
    path: "/ticket_sets/:filter?/",
    component: _b6c5ce7a,
    pathToRegexpOptions: {"strict":true},
    name: "ticket_sets-filter"
  }, {
    path: "/videos/:filter?/",
    component: _5b3219ce,
    pathToRegexpOptions: {"strict":true},
    name: "videos-filter"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
