
import Common from '~/mixins/common'
import Rating from '~/components/util/Rating'

export default {
  mixins: [Common],
  components: {
    Rating,
  },
  props: {
    event_sets: Array,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  filters: {
  },
  computed: {
  },
  methods: {
    current_event(event_set){
      let training_event = _.find(this.training_events, ["uid", event_set.event_uid])
      if(training_event){
        training_event.is_able = true
        return training_event
      }else{
        // MEMO:種目削除した場合はこっちになる
        event_set.event.is_able = false
        return event_set.event
      }
    },
    current_category_name(event_set){
      let training_category = _.find(this.training_categories, ["id", this.current_event(event_set).training_category_id])
      return training_category ? training_category.name : "-"
    },
  },
}
