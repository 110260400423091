
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import Compressor from 'compressorjs'
  
  export default {
    components: {
      VueCropper,
    },
    props: {
      label_id: { type: String, default: "image_file" },
      useCropper: { type: Boolean, default: false },
      width: { type: Number, default: 450 },
      height: { type: Number, default: 450 },
      image: Object,
    },
    data() {
      return {
        image_preview: null,
        showDetail: false,
        imgFile: null,
        hasFile: false,
        max_width: 350,
      }
    },
    watch: {
      image: function(new_file, old_file){
        if(new_file && new_file.size_m && new_file.size_m.url) this.hasFile = true
      },
    },
    async mounted() {
      let self = this
      if(self.$store.state.auth.isPC) self.max_width = 800
      if(self.image && self.image.size_m && self.image.size_m.url) self.hasFile = true
    },
    methods: {
      cancel(){
        let self = this
        self.showDetail = false
      },
      deleteFile(){
        console.log("deleteFile")
        let self = this
        self.image_preview = null
        self.imgFile = null
        self.hasFile = false
        self.$emit("setFile", null);
        self.$emit("clearFile");
      },
      setImage: async function(event) {
        console.log("setImage")
        let self = this
        const file = event.target.files[0]
        if (!file.type.includes("image/")) {
          window.storeCtl.commit("alert/setError", "画像ファイルを選択してください")
          return;
        }
        if(file.size > 1024 * 1024 * 10){
          window.storeCtl.commit("alert/setError", "ファイルサイズは10MB以下にしてください")
          this.files = []
          return 
        }
  
        // クロッパー使うかどうか
        if(this.useCropper){
          window.storeCtl.commit("loading/start")
          this.showDetail = true
          if (typeof FileReader === "function") {
            const reader = new FileReader();
            reader.onload = event => {
              this.imgFile = event.target.result;
              this.$refs.cropper.replace(event.target.result);
            };
            reader.readAsDataURL(file);
            window.storeCtl.commit("loading/stop")
          } else {
            alert("Sorry, FileReader API not supported");
          }
        }else{
          self.deleteFile()
          self.$emit("setFile", file);
          self.image_preview = URL.createObjectURL(file)
          self.hasFile = true
          self.showDetail = false
          self.$refs['file-input'].reset()
        }
      },
      // rotate(){
      //   this.$refs.cropper.move(1, -1).rotate(45).scale(1, -1)
      // },
      saveImage: async function() {
        let self = this
        window.storeCtl.commit("loading/start")
        self.deleteFile()
        let imgCanvas = self.$refs.cropper.getCroppedCanvas();
        imgCanvas.toBlob(async (blob) => {
          const newFile = await new Promise((resolve, reject) => {
            new Compressor(blob, {
              quality: 0.8,
              maxWidth: 1500,
              maxHeight: 1500,
              success: resolve,
              error: reject,
            });
          });
          self.$emit("setFile", newFile);
          self.image_preview = imgCanvas.toDataURL()
          self.hasFile = true
          self.showDetail = false
          self.$refs['file-input'].reset()
          window.storeCtl.commit("loading/stop")
        })
      },
    }
  }
  