
import Common from '~/mixins/common'
import ClientMessage from '~/components/common/client/Message'

export default {
  mixins: [Common],
  components: {
    ClientMessage,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showPassword: false,
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
  },
}
