
import Common from '~/mixins/common'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import EditClient from '~/components/common/client/Edit'
import ClientJoin from '~/components/common/client/Join'
import EditClientCourse from '~/components/common/client/EditCourse'
import EditMemo from '~/components/common/client/EditMemo'
import ClientMessage from '~/components/common/client/Message'
import ClPaymentNew from '~/components/common/cl_payment/New'
import ReservationBulkNew from '~/components/common/reservation/BulkNew'
import LessonChart from '~/components/common/lesson/Chart'
import ClientDetail from '~/components/common/client/Detail'
import TaskEdit from '~/components/common/task/Edit'

export default {
  mixins: [Common],
  components: {
    EditClient,
    ClientJoin,
    EditMemo,
    ClientMessage,
    ClPaymentNew,
    ReservationBulkNew,
    LessonChart,
    ClientDetail,
    TaskEdit,
    EditClientCourse,
  },
  props: {
    client: Object,
    showDetail: {type: Boolean, default: true}
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
  },
  computed: {
    company_name(){
      const company = _.find(this.companies, {id: this.client.company_id})
      return company ? company.name : ''
    }
  },
  methods: {
    async updatePassword(){
      let self = this
      self.$bvModal.msgBoxConfirm('この操作を実行すると、パスワードが変更されます。', {
        title: 'パスワード変更してよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${self.client.uid}/update_password.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "パスワードを更新しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    async forceLogout(){
      let self = this
      self.$bvModal.msgBoxConfirm('この操作を実行すると、現在ログインしている顧客に再度認証が求められます。', {
        title: '強制ログアウトしてよろしいですか？',
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${self.client.uid}/force_logout.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "強制ログアウトしました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    async updateSkipDeviseToken(){
      let self = this
      let title = null
      let message = null
      if(self.client.skip_devise_token){
        title = "認証コードを有効にする"
        message = "この操作を実行すると、ログインの際に登録メールアドレスに認証コードが飛びます。"
      }else{
        title = "認証コードを無効にする"
        message = "この操作を実行すると、顧客IDとパスワードのみでログインができます。セキュリティレベルを高めたい場合は無効にしないでください。"
      }
      self.$bvModal.msgBoxConfirm(message, {
        title: title,
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${self.client.uid}/update_by_key.json`, {
              key: "skip_devise_token",
              value: !self.client.skip_devise_token,
              client: self.client,
            })
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", "設定を更新しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },

    async changeStatus(){
      let self = this
      let title = null
      let message = null
      let alert_message = null
      if(self.client.status === 'active'){
        title = "停止してよろしいですか？"
        message = "停止すると顧客がログインできなくなります"
        alert_message = "停止しました"
      }else{
        title = "再開してよろしいですか？"
        message = "再開すると顧客がログインでるようになります"
        alert_message = "再開しました"
      }
      self.$bvModal.msgBoxConfirm(message, {
        title: title,
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .put(`/cmp/floor/clients/${self.client.uid}/update_status.json`, {
              client: self.client,
            })
            .then(async (res) => {
              if (res.data.status === 'success') {
                window.storeCtl.commit("loading/stop")
                self.$emit("updated", res.data.data.client)
                window.storeCtl.commit("alert/setSuccess", alert_message)
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
    async destroy(){
      let self = this
      self.$bvModal.msgBoxConfirm('削除すると顧客のデータが失われます。また削除するには予約中のレッスンを完了またはキャンセルしてください。', {
        title: "削除してよろしいですか？",
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          self.$store.commit("loading/start")
          await httpClient
            .delete(`/cmp/floor/clients/${self.client.uid}.json`)
            .then(async (res) => {
              if (res.data.status === 'success') {
                self.$emit("destroied", res.data.data.client)
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setSuccess", "削除しました")
              } else {
                window.storeCtl.commit("loading/stop")
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
  },
}
