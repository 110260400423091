
import { Line } from 'vue-chartjs'
// import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import dayjs from 'dayjs';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  extends: Line,
  props: {
    trends: Array,
  },
  components: {
    // ChartJsPluginDataLabels,
  },
  computed: {
  },
  watch: {
    trends: function (trends) {
      // console.log("trends watch",trends)
      this.updateChart(trends)
    },
  },
  mounted() {
    this.updateChart(this.trends);
  },
  data: function () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            yAxisID: "max_weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: 'ベンチプレス',
            backgroundColor: '#f597cb',
            borderColor: '#f597cb',
            fill: false,
            data: [],
            spanGaps: true,
          },
          {
            yAxisID: "max_weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: 'スクワット',
            backgroundColor: '#97aaf5',
            borderColor: '#97aaf5',
            fill: false,
            data: [],
            spanGaps: true,
          },
          {
            yAxisID: "max_weight",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: 'デッドリフト',
            backgroundColor: '#c8aaee',
            borderColor: '#c8aaee',
            fill: false,
            data: [],
            spanGaps: true,
          },
          // {
          //   yAxisID: "weight",
          //   lineTension: 0,
          //   type: 'line',
          //   label: '体重',
          //   backgroundColor: '#2F54EB',
          //   borderColor: '#2F54EB',
          //   fill: false,
          //   data: [],
          //   spanGaps: true,
          //   // hidden: true,
          // },
        ]
      },
      options: {
        legend : {
          // display : false,
          position: "bottom", 
          labels: {
            fontSize: 10,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { /* ここです */
          callbacks: {
            label: function(tooltipItem, data){
              // this.trendsが取れない
              // console.log("tooltips",tooltipItem,data)
              // console.log("date",this.trends,tooltipItem.label)
              // let trend = _.find(this.trends, ['date', tooltipItem.label])
              // console.log("trend",trend)
              // let message = `${tooltipItem.yLabel} / 食事：${trend.food} / 睡眠：${trend.sleep}`
              // return message
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '日付'
            },
            ticks : {
              minRotation: 0,
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 4,
              callback: function(tick, index) {
                let str = dayjs(tick).format("YY/MM/DD");
                // let isRetern = false
                // if(index === 0) isRetern = true
                // if((index + 1) % 5 === 0) isRetern = true
                // return isRetern ? str : "";
                return str
              }
            }
          }],
          yAxes: [
            {
              id: "max_weight",
              type: "linear",
              position: "left",
              ticks: {
                // min: 10,
                // max: 20,
                beginAtZero: false,
                maxTicksLimit: 20,
                stepSize: 5,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "kg" : ''
                }
              }
            },
            // {
            //   id: "weight",
            //   type: "linear",
            //   position: "right",
            //   gridLines: {
            //     //y軸の網線
            //     display: false //表示するか否か
            //   },
            //   // https://tr.you84815.space/chartjs/axis/cartesian/linear.html
            //   ticks: {
            //     // min: 45,
            //     // max: 55,
            //     beginAtZero: false,
            //     maxTicksLimit: 20,
            //     stepSize: 0.5,
            //     callback: function(label, index, labels) { /* ここです */
            //       return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "kg" : ''
            //     }
            //   }
            // },
          ]
        },
      }
    }
  },
  methods: {
    updateChart(trends){
      console.log("trends",trends)
      trends = _.orderBy(trends, 'date', 'asc');
      // データ加工
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      this.chartdata.datasets[1].data = []
      this.chartdata.datasets[2].data = []
      // this.chartdata.datasets[3].data = []
      trends.forEach(tr => {
        this.chartdata.labels.push(tr.date)
        this.chartdata.datasets[0].data.push(tr.max_weight_bench_press)
        this.chartdata.datasets[1].data.push(tr.max_weight_squat)
        this.chartdata.datasets[2].data.push(tr.max_weight_deadlift)
        // this.chartdata.datasets[3].data.push(tr.weight)
      })

      // 設定
      this.options.scales.xAxes[0].ticks.maxTicksLimit = this.$store.state.devise.isPC ? 10 : 4

      // ラベル
      this.chartdata.datasets[0].label = this.lesson_setting.max_weight_bench_press.title
      this.chartdata.datasets[1].label = this.lesson_setting.max_weight_squat.title
      this.chartdata.datasets[2].label = this.lesson_setting.max_weight_deadlift.title
      this.chartdata.datasets[0].hidden = !this.lesson_setting.max_weight_bench_press.use
      this.chartdata.datasets[1].hidden = !this.lesson_setting.max_weight_squat.use
      this.chartdata.datasets[2].hidden = !this.lesson_setting.muscle_weight.use
      // legend
      let ng_names = []
      if(!this.lesson_setting.max_weight_bench_press.use) ng_names.push(this.lesson_setting.max_weight_bench_press.title)
      if(!this.lesson_setting.max_weight_squat.use) ng_names.push(this.lesson_setting.max_weight_squat.title)
      if(!this.lesson_setting.max_weight_deadlift.use) ng_names.push(this.lesson_setting.max_weight_deadlift.title)
      this.options.legend.labels.filter = function(legendItem, data) {
        if(ng_names.includes(legendItem.text)) return false
        return legendItem.text
      }
      // yAxes
      let unit = null;
      unit = this.lesson_setting.max_weight_deadlift.tani; // '%'を取得
      unit = this.lesson_setting.max_weight_squat.tani; // '%'を取得
      unit = this.lesson_setting.max_weight_bench_press.tani; // '%'を取得
      this.options.scales.yAxes[0].ticks.callback = function(label) {
        return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + unit : ''
      }

      this.renderChart(this.chartdata, this.options)

    },
  }
}
