
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    lesson: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    // options
    lesson_option_price(lesson){
      if(lesson.reesrve_data.option_settings.use_menu_option === false) return null
      return lesson.reesrve_data.option_settings.menu_option_price
    },
    lesson_calendar_price(lesson){
      if(lesson.reesrve_data.option_settings.use_calendar_option === false) return null
      let cal_option = _.find(lesson.reesrve_data.option_settings.calendar_options, ["id", lesson.calendar_id])
      return cal_option ? cal_option.price : 0
    },
  },
}
