
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import TaskEdit from '~/components/common/task/Edit'
import TaskStatusChip from '~/components/common/task/StatusChip'

export default {
  mixins: [Common],
  components: {
    TaskEdit,
    TaskStatusChip,
  },
  props: {
    client: Object,
    getSelf: {type: Boolean, default: true},
    tasks: {type: Array, default: () => ([])},
  },
  data() {
    return {
      client_tasks: [],
    }
  },
  async mounted() {
    this.client_tasks = _.filter(this.tasks, ["client_id", this.client.id])
    if(this.getSelf) this.getClientTasks()
  },
  watch: {
    tasks(array){
      this.client_tasks = _.filter(array, ["client_id", this.client.id])
    },
  },
  computed: {
  },
  methods: {
    async getClientTasks(){
      let self = this
      await httpClient
        .get(`/cmp/floor/tasks.json?client_id=${self.client.id}&status_eq_any=inprogress`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.client_tasks = res.data.data.tasks
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    updated(){
      if(this.getSelf) this.getClientTasks()
      this.$emit('updated')
    }
  },
}
