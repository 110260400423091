
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import Common from '~/mixins/common'
import { timeOptions } from '~/utils/tool'
import dayjs from 'dayjs';
import InputColor from '~/components/util/InputColor'
import InputTime from '~/components/util/InputTime'

export default {
  mixins: [Common],
  components: {
    InputColor,
    InputTime,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showEditReservationModal: false,
      timeOptions: timeOptions(), // 時間

      edit_lesson: null,
      edit_lesson_calendar_id: null,
      edit_lesson_resource_id: null,

      // 時間周り
      lessonStartDate: null,
      lessonStartHour: null,
      lessonEndDate: null,
      lessonEndHour: null,
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
    client(val){
      // this.current_lesson = _.cloneDeep(val)
    },
  },
  computed: {
    reserve_push:{
      get(){
        return this.$store.state.company.reserve_push
      },
      set(newVal){
        window.storeCtl.commit("company/setReservePush", newVal)
        console.log("reserve_push")
      }
    },
  },
  methods: {    
    changeDateTime(){
      let reserve_min = dayjs(this.edit_lesson.end).diff(dayjs(this.edit_lesson.start), "minute")
      console.log("changeDateTime", reserve_min)
      let lesson_end = dayjs(`${this.lessonStartDate} ${this.lessonStartHour}`).add(reserve_min, "m").format("YYYY-MM-DD HH:mm")
      this.lessonEndDate = _.cloneDeep(dayjs(lesson_end).format('YYYY-MM-DD'))
      this.lessonEndHour = _.cloneDeep(dayjs(lesson_end).format('HH:mm'))
    },

    //// トレーナー変更 ///////////////////////////////////////
    openChangeReservation(lesson){
      console.log("openChangeReservation")
      if(!this.staff.can_reserve) return
      this.edit_lesson = _.cloneDeep(lesson)
      this.edit_lesson_calendar_id = this.edit_lesson.calendar ? this.edit_lesson.calendar.id : null
      this.edit_lesson_resource_id = this.edit_lesson.resource ? this.edit_lesson.resource.id : null
      this.lessonStartDate = _.cloneDeep(dayjs(this.edit_lesson.start).format('YYYY-MM-DD'))
      this.lessonStartHour = _.cloneDeep(dayjs(this.edit_lesson.start).format('HH:mm'))
      this.lessonEndDate = _.cloneDeep(dayjs(this.edit_lesson.end).format('YYYY-MM-DD'))
      this.lessonEndHour = _.cloneDeep(dayjs(this.edit_lesson.end).format('HH:mm'))
      this.showEditReservationModal = true
    },
    async changeReservation(){
      let self = this
      self.edit_lesson.start = `${self.lessonStartDate} ${self.lessonStartHour}`
      self.edit_lesson.end = `${self.lessonEndDate} ${self.lessonEndHour}`
      if(dayjs(self.edit_lesson.start).isAfter(dayjs(self.edit_lesson.end))){
        window.storeCtl.commit("alert/setError", "開始時間は終了時間よりも前に設定してください")
        return
      }
      self.$store.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/lessons/${self.edit_lesson.uid}/change.json`, {
          block_name: self.edit_lesson.block_name,
          memo: self.edit_lesson.memo,
          inner_memo: self.edit_lesson.inner_memo,
          color: self.edit_lesson.color,
          calendar_id: self.edit_lesson_calendar_id,
          resource_id: self.edit_lesson_resource_id,
          start: self.edit_lesson.start,
          end: self.edit_lesson.end,
          push: self.reserve_push,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            // self.changeCalendars()
            // self.clickInfo.event.extendedProps.lesson = res.data.data.lesson
            self.$emit("updated", res.data.data.lesson)
            self.showEditReservationModal = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },

  },
}
