
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import _ from 'lodash';
import ClientProfile from '~/components/common/client/Profile'
import EditClient from '~/components/common/client/Edit'
import ClientMenu from '~/components/common/client/Menu'
import LessonLists from '~/components/common/lesson/Lists'
import ClientTrendLists from '~/components/common/client_trend/Lists'
import BosyscanLists from '~/components/common/bodyscan/Lists'
import ClPaymentLists from '~/components/common/cl_payment/Lists'
// import DocLists from '~/components/common/doc/Lists'
import PointLists from '~/components/common/point/Lists'
import TicketSetLists from '~/components/common/ticket_set/Lists'
import AlertLists from '~/components/common/alert/Lists'
import LessonChart from '~/components/common/lesson/Chart'
import StatusChip from '~/components/common/client/StatusChip'
import TrainingEvents from '~/components/common/client/TrainingEvents'
import IconAvatar from '~/components/util/IconAvatar'
import Tabs from '~/components/util/Tabs'
import TaskLists from '~/components/common/task/Lists'
import SurveyAnswers from '~/components/common/survey/Answers'
import TrainingListLists from '~/components/common/training_list/Lists'
import GoalTermLists from '~/components/common/goal_term/Lists'
import ClientOtherCompanyChip from '~/components/common/client/OtherCompanyChip'

export default {
  mixins: [Common],
  components: {
    EditClient,
    ClientMenu,
    LessonLists,
    ClientTrendLists,
    BosyscanLists,
    TrainingEvents,
    ClPaymentLists,
    // DocLists,
    PointLists,
    TicketSetLists,
    AlertLists,
    LessonChart,
    StatusChip,
    IconAvatar,
    Tabs,
    TaskLists,
    SurveyAnswers,
    ClientProfile,
    TrainingListLists,
    GoalTermLists,
    ClientOtherCompanyChip,
  },
  props: {
    client: Object,
    lesson_status: {type: Array, default: null},
  },
  data() {
    return {
      showModal: false,
      current_client: null,
      tabIndex: 0,
      lessonTabIndex: 0,
      paymentTabIndex: 0,
      settingTabIndex: 0,
      tabOptions: [
        {label: "プロフ", value: 0, disabled: false},
        {label: "予約・記録", value: 1, disabled: false},
        {label: "支払・チケ", value: 2, disabled: false},
        {label: "その他", value: 3, disabled: false},
      ],
      lessonTabOptions: [
        {label: "トレーニング", value: 0, disabled: false},
        {label: "ライフログ", value: 1, disabled: false},
        {label: "3Dスキャン", value: 2, disabled: false},
        {label: "種目", value: 3, disabled: false},
        {label: "種目セット", value: 4, disabled: false},
        {label: "チャレンジ", value: 5, disabled: false},
      ],
      paymentTabOptions: [
        {label: "支払い", value: 0, disabled: false},
        {label: "チケット", value: 1, disabled: false},
        {label: "ポイント", value: 2, disabled: false},
      ],
      settingTabOptions: [
        {label: "タスク", value: 0, disabled: false},
        {label: "アンケート", value: 1, disabled: false},
        {label: "お知らせ", value: 2, disabled: false},
      ],
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
    selectedCourseName(){
      let course = _.find(this.courses, ["id", this.current_client.course_id])
      return course ? course.name_inner : "-"
    },
  },
  methods: {
    changeTab(event){
      this.tabIndex = event.newTabIndex
    },
    lessonChangeTab(event){
      this.lessonTabIndex = event.newTabIndex
    },
    paymentChangeTab(event){
      this.paymentTabIndex = event.newTabIndex
    },
    settingChangeTab(event){
      this.settingTabIndex = event.newTabIndex
    },
    async open(client_uid, tabType = null){
      // 権限設定
      this.lessonTabOptions[1].disabled = !this.headquarter.use_life_logs || !this.staff.can_log
      this.lessonTabOptions[2].disabled = !this.headquarter.use_bodyscan || !this.staff.can_client
      this.paymentTabOptions[0].disabled = !this.staff.can_payment
      this.paymentTabOptions[2].disabled = !this.headquarter.use_point || !this.staff.can_point

      // タブ
      this.tabIndex = 0
      this.lessonTabIndex = 0
      this.paymentTabIndex = 0
      this.settingTabIndex = 0
      if(tabType === "reserved_lessons"){
        this.tabIndex = 1
        this.lessonTabIndex = 0
        this.paymentTabIndex = 0
        this.settingTabIndex = 0
      }else if(tabType === "tasks"){
        this.tabIndex = 3
        this.lessonTabIndex = 0
        this.paymentTabIndex = 0
        this.settingTabIndex = 0
      }else if(tabType === "client_trends"){
        this.tabIndex = 1
        this.lessonTabIndex = 1
        this.paymentTabIndex = 0
        this.settingTabIndex = 0
      }

      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
        .get(`/cmp/floor/clients/${client_uid}.json`)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            window.storeCtl.commit("company/setClient", res.data.data.client)
            self.current_client = res.data.data.client
            // タスクバッジ
            this.tabOptions[3].badge = self.current_client.task_size
            this.settingTabOptions[0].badge = self.current_client.task_size
            self.$emit("opened")
            self.showModal = true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
        })
    },
    async refreshClient(){
      await httpClient
        .get(`/cmp/floor/clients/${this.current_client.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.clientUpdated(res.data.data.client)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    clientUpdated(client){
      this.current_client = client
      this.$emit("updated", client)
    },
    clientDeleted(client){
      this.current_client = client
      this.showModal = false
      this.$emit("deleted")
    },
  },
}
