
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
    type: { type: String, default: 'introduction' }
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
    isShow(){
      if(this.type === 'introduction'){
        return this.client && this.client.introduction_code
      }else{
        return this.client && this.client.tracking_code
      }
    },
    title(){
      return this.type === 'introduction' ? '紹介' : '経路'
    },
    data(){
      return this.type === 'introduction' ? this.client.introduction_code : this.client.tracking_code
    }
  },
  methods: {
  },
}
