
import _ from 'lodash'
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
    company_name(){
      const company = _.find(this.companies, {id: this.client.company_id})
      return company ? company.name : ''
    }
  },
  methods: {
  },
}
