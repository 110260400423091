
export default {
  components: {
  },
  props: {
    model: Object,
    size: {type: Number, default: 80},
    icon: {type: String, default: "mdi-account"}
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
}
