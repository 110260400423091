
import dayjs from 'dayjs';
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import Common from '~/mixins/common'
import ChartBody from '~/components/common/lesson/ChartBody'
import ChartMuscle from '~/components/common/lesson/ChartMuscle'
import ChartReview from '~/components/common/lesson/ChartReview'
import Tabs from '~/components/util/Tabs'
import ClientChartBody from '~/components/common/client_trend/ChartBody'

export default {
  mixins: [Common],
  components: {
    ChartBody,
    ChartMuscle,
    ChartReview,
    Tabs,
    ClientChartBody,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      from_date: null,
      to_date: null, 

      tabIndex: 0,
      tabOptions: [
        {label: "体組成", value: 0, disabled: false},
        {label: "重量", value: 1, disabled: false},
        {label: "レビュー", value: 2, disabled: false},
        {label: "ライフログ", value: 3, disabled: false},
      ],

      showModal: false,
      tabChart: "body",
      current_client: null,
      trends: [],
      client_trends: [],
    }
  },
  async mounted() {
    let self = this
  },
  watch: {
    client(val){
      let self = this
      self.current_client = _.cloneDeep(val)
    },
  },
  computed: {
  },
  methods: {
    changeTab(event){
      this.tabIndex = event.newTabIndex
    },
    show(client){
      console.log("show",client)
      this.current_client = _.cloneDeep(client)
      this.from_date = dayjs().subtract(12,'month').format("YYYY-MM-DD")
      this.to_date = dayjs().format("YYYY-MM-DD")
      this.getAllTrends()
      this.showModal = true
    },
    termExpand(){
      this.from_date = dayjs().subtract(10,'year').format("YYYY-MM-DD")
      this.to_date = dayjs().format("YYYY-MM-DD")
      this.getAllTrends()
    },
    changeDate(){
      if(dayjs(this.from_date).add(10, 'y').isBefore(dayjs(this.to_date))){
        window.storeCtl.commit("alert/setError", "期間は3650日以下にしてください")
        return
      }
      this.getAllTrends()
    },
    async getAllTrends(){
      window.storeCtl.commit("loading/start")
      await Promise.all([this.getTrendsOfLesson(),this.getTrendsOfClient()])
      window.storeCtl.commit("loading/stop")
    },
    async getTrendsOfLesson(){
      let self = this
      await httpClient
      .get('/cmp/floor/lessons/trends.json', {params: {
        client_uid: self.current_client.uid,
        from_date: self.from_date,
        to_date: self.to_date,
      }})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.trends = res.data.data.lessons
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    },
    async getTrendsOfClient(){
      let self = this
      await httpClient
      .get('/cmp/floor/client_trends/trends.json', {params: {
        client_uid: self.current_client.uid,
        from_date: self.from_date,
        to_date: self.to_date,
      }})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.client_trends = res.data.data.client_trends
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
    },  },
}
