
import _ from 'lodash';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    data: { type: null },
    custom_input: {type: Object}
  },
  data() {
    return {
      edit_data: null,
      edit_data_arr: [],
    }
  },
  async mounted() {
    this.edit_data = _.cloneDeep(this.data)
    this.edit_data_arr = (this.data || "").split(",");
  },
  watch: {
    data(obj){
      this.edit_data = _.cloneDeep(obj)
      this.edit_data_arr = (obj || "").split(",");
    },
  },
  computed: {
    statusOptions() {
      return (　this.custom_input.option || "").split(",");
    }
  },
  methods: {
  }
}
