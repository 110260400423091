
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    initTab: {type: [String, Number], default: 0},
    tabOptions: Array,
    second: {type: Boolean, default: false},
  },
  data() {
    return {
      currentTab: null,
    }
  },
  async mounted() {
    this.currentTab = this.initTab 
  },
  computed: {
  },
  methods: {
    click(tab, t_idx){
      if(tab.disabled) return
      let old_tab_value = _.cloneDeep(this.currentTab)
      this.currentTab = tab.value
      this.$emit("change", {
        oldTabIndex: old_tab_value,
        newTabIndex: tab.value
      })
      // タブの位置調整
      setTimeout(() => {
        const selectedElement = this.$refs.tab[t_idx]
        if(selectedElement) selectedElement.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
      }, 100)
    },
  },
}
