
export default {
  components: {
  },
  props: {
    size: Number,
    name: String,
    image: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
  },
}
