
export default {
  data() {
    return {
      step: 1,
    }
  },
  methods: {
    close() {
      this.$store.commit('devise/firstContactDone', true)
      this.$store.commit('modal/setFirstContactModal', false)
    },
  }
}
