
export default {
  components: {
  },
  props: {
    objCount: Number,
  },
  watch: {
    objCount: function (objCount) {
      this.currentNumber = objCount
    },
  },
  data() {
    return {
      currentNumber: 0,
    }
  },
  async mounted() {
    this.currentNumber = this.objCount
  },
  computed: {
  },
  methods: {
    addNumberStr(numberStr){
      const currentNumberStr = String(this.currentNumber) + String(numberStr)
      this.currentNumber = parseInt(currentNumberStr)
      this.$emit("changeSize",this.currentNumber);
    },
    clickBackSpace(){
      let currentNumberStr = String(this.currentNumber)
      if(currentNumberStr.length > 1){
        this.currentNumber = parseInt(currentNumberStr.slice(0, -1));
      }else{
        this.currentNumber = 0
      }
      this.$emit("changeSize",this.currentNumber);
    },
  },
}
