
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    size: {type: String, required: false, default: "sm"},
    rating: {type: Number, required: false, default: null},
    readOnly: {type: Boolean, default: false},
  },
  data() {
    return {
      current_rating: null,
    }
  },
  async mounted() {
    this.current_rating = this.rating 
  },
  computed: {
  },
  methods: {
    click(number){
      if(this.readOnly) return
      this.current_rating = number
      this.$emit("click", number)
    },
  },
}
