
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    time: String,
    disabled: {type: Boolean, default: false},
  },
  data() {
    return {
      clock_mode: true,
      init_time: null,
      edit_time: null,
      editing: false,
      allowedHours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23],
      allowedMinutes: [0,5,10,15,20,25,30,35,40,45,50,55],
      selectHours: ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
      selectMinutes: ["00","05","10","15","20","25","30","35","40","45","50","55"],
      edit_hour: null,
      edit_minutes: null,
    }
  },
  async mounted() {
    this.init_time = this.time
    this.edit_time = this.time
  },
  watch: {
    time(val){
      this.init_time = val
      this.edit_time = val
    },
  },
  computed: {
  },
  methods: {
    change_clock_mode(){
      if(this.clock_mode){
        const [hours, minutes] = this.edit_time.split(":");
        this.edit_hour = hours
        this.edit_minutes = minutes
      }
      this.clock_mode = !this.clock_mode
    }, 
    change_selecter(){
      this.edit_time = `${this.edit_hour}:${this.edit_minutes}`
    },

    onMinuteClick(minute) {
      // 同じ分を選択しても更新されるように強制的に値をセット
      const [hour, currentMinute] = this.edit_time.split(':');
      if (parseInt(currentMinute) === minute) {
        this.edit_time = `${hour}:${minute.toString().padStart(2, '0')}`;
      }
      this.update_time(this.edit_time)
    },
    openModal(){
      if(this.disabled) return
      this.editing = true
    },
    update_time(e){
      console.log("update_time",e)
      this.edit_time = e
      this.set()
    },
    reset(){
      this.editing = false
    },
    set(){
      this.init_time = this.edit_time
      this.editing = false
      // console.log("set",this.edit_time)
      this.$emit("change", this.edit_time)
    },
  },
}
