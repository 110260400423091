
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import _ from 'lodash';
import ChatMessage from '~/components/common/chat/Message'
import ChatForm from '~/components/common/chat/Form'
import InfiniteLoading from 'vue-infinite-loading'

// https://www.kabanoki.net/4697/
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

export default {
  mixins: [Common],
  components: {
    ChatMessage,
    ChatForm,
    InfiniteLoading,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    chat_room: Object,
  },
  data() {
    return {
      chat_interval: null,
      current_chat_room: null,
      chat_pages: {
        total_count: 0,
        total_pages: 0,
        current_page: 0,
        next_page: 1,
      },
      current_chats: [],
      identifier: 1,
    }
  },
  async mounted() {
    let self = this
    if(self.chat_room) self.current_chat_room = self.chat_room

    self.chat_interval = setInterval(()=>{
      self.autoSearch();
    }, 10 * 1000)
  },
  destroyed: function () {
    clearInterval(this.chat_interval)
  },
  watch: {
    // client(val){
    //   let self = this
    //   self.current_client = _.cloneDeep(val)
    // },
  },
  computed: {
    filterd_chats(){
      let filterd_chats = _.cloneDeep(this.current_chats)
      filterd_chats = _.orderBy(filterd_chats, 'id', 'asc');
      return filterd_chats
    },
  },
  methods: {
    scrollToBottom(){
      setTimeout(()=>{
        // 画面下部までスクロール
        // https://mebee.info/2020/11/23/post-17840/
        var elm = document.documentElement;
        var bottom = elm.scrollHeight - elm.clientHeight;
        window.scroll(0, bottom);
      }, 1000 * 0.1);
    },

    async selectChatRoom(chat_room){
      this.current_chat_room = chat_room
      this.identifier += 1
      await this.reset()
      this.$refs.chatForm.clear()
      this.scrollToBottom()
    },

    reset(){
      this.current_chats = []
      this.chat_pages = {
        current_page: 0,
        next_page: 1,
      }
    },
    async infiniteHandler($state) {
      let self = this
      if (self.chat_pages.next_page > self.chat_pages.current_page) {
        await self.search(self.chat_pages.next_page)
        $state.loaded()
      } else if (!self.chat_pages.next_page) {
        $state.complete()
      }
    },

    async search(page){
      let self = this
      if(!self.current_chat_room) return
      await httpClient
        .get(`/cmp/floor/chat_msgs.json`, {
          params: {
            chat_room_uid: self.current_chat_room.uid,
            page: page ? page : self.chat_pages.current_page,
          }
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            self.chat_pages = res.data.data.pages
            let current_chats = self.current_chats.concat(res.data.data.chat_msgs)
            current_chats = _.orderBy(current_chats, 'updated_at', 'desc');
            self.current_chats = _.uniqBy(current_chats, "id")
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async autoSearch(){
      let self = this
      if(!self.current_chat_room) return
      await httpClient
        .get(`/cmp/floor/chat_msgs.json`, {
          params: {
            chat_room_uid: self.current_chat_room.uid,
            page: 1,
          }
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            let current_chats = self.current_chats.concat(res.data.data.chat_msgs)
            current_chats = _.orderBy(current_chats, 'updated_at', 'desc');
            self.current_chats = _.uniqBy(current_chats, "id")

            //　もし新しいメッセージが来たら下までいく。
            self.scrollToBottom()
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },


    chatSent(new_chat){
      this.current_chats.unshift(new_chat)
      this.scrollToBottom()
    },
    chatMessageUpdated(updated_chat){
      let self = this
      let new_chats = []
      self.current_chats.forEach(ct => {
        if(ct.uid === updated_chat.uid){
          new_chats.push(updated_chat)
        }else{
          new_chats.push(ct)
        }
      })
      self.current_chats = new_chats
    },
  },
}
