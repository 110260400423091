
import _ from 'lodash';
import { mapState } from 'vuex'
import dayjs from 'dayjs';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
  },
  data() {
    return {
      menuModal: false,
      showWater: false,
      showBody: false,
      showSleep: false,
      showWalk: false,
      showBreakfast: false,
      showLunch: false,
      showDinner: false,
      showSnack: false,
      showBeer: false,

      memo: null,
      weight: 0,
      fat_rate: 0,
      
    }
  },
  async mounted() {
  },
  computed: {
    ...mapState({
      loginUser: state => state.loginUser,
    }),
  },
  methods: {
    openMenu(){
      this.menuModal = true
    }
  },
}
