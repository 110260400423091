
import _ from 'lodash';
import Common from '~/mixins/common'
import ClientTrendForm from '~/components/common/client_trend/Form'
import ClientTrendReport from '~/components/common/client_trend/Report'
import DocumentInputs from '~/components/util/docs/DocumentInputs'

export default {
  mixins: [Common],
  components: {
    ClientTrendForm,
    ClientTrendReport,
    DocumentInputs,
  },
  props: {
    client: {type: Object, default: null},
    client_trend: {type: Object, default: null},
  },
  data() {
    return {
      // data
      edit_client_trend: null,
    }
  },
  async mounted() {
    this.edit_client_trend = _.cloneDeep(this.client_trend)
  },
  watch: {
    client_trend(val){
      this.edit_client_trend = _.cloneDeep(val)
    }
  },
  computed: {
    filterdDocs(){
      let docs = _.filter(this.edit_client_trend.docs, (d) => {
        return this.edit_client_trend.food_doc_ids.includes(d.id)
      }) 
      return docs      
    },
    filterdCmpDocs(){
      let docs = _.filter(this.edit_client_trend.docs, (d) => {
        return this.edit_client_trend.daialy_reply_doc_ids.includes(d.id)
      }) 
      return docs      
    },
    clientRatingText(){
      let rating = parseInt(this.edit_client_trend.client_rating) 
      if(rating === 5) return "とても良い"
      if(rating === 4) return "良い"
      if(rating === 3) return "普通"
      if(rating === 2) return "あまり"
      if(rating === 1) return "悪い"
      if(rating === null) return "-"
    },
  },
  filters: {
    boolToDone(val){
      return val ? "出来た" : "出来なかった"
    },
  },
  methods: {
    updated(event){
      this.edit_client_trend = _.cloneDeep(event)
      this.$emit("updated", event)
    },
  }
}
