export default function ({ app, store }) {
  if (!app.$bugsnag) return;

  // ユーザー情報の設定
  const setUserInfo = () => {
    const staff = store.state.auth.staff;
    // console.log("setUserInfo", staff.name)
    if (staff) {
      app.$bugsnag.setUser(staff.uid, staff.email, staff.name);
    } else {
      app.$bugsnag.setUser(null);
    }
  };

  // 店舗情報の設定
  const setCompanyInfo = () => {
    const company = store.state.auth.company;
    // console.log("setCompanyInfo", company.name)
    if (company) {
      app.$bugsnag.addMetadata('company', {
        id: company.id || 'unknown',
        uid: company.uid || 'unknown',
        name: company.name || 'unknown',
      });
    }
  };

  // 初期設定
  setUserInfo();
  setCompanyInfo();

  // 状態の変更を監視
  store.subscribe((mutation) => {
    
    if (mutation.type === 'auth/setAuthToken') {
      setTimeout(() => {
        // console.log("auth/setAuthToken")
        setUserInfo();
        setCompanyInfo();
      }, 500);
    }
  });
}
