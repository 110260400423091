import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    sp: 499, // 959,
    pc: 500 // 960
  },
  defaultBreakpoint: 'sp'
})
