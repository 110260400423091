
import VideoInput from '~/components/util/videos/VideoInput'
import { httpClient } from '~/utils/axios'
import axios from 'axios'

export default {
  components: {
    VideoInput,
  },
  props: {
    inline: {type: Boolean, default: false},
    key_index: {type: String, default: 1},
    videos: { type: Array },
    doc_type: { type: String, default: "other" },
    showCreatedAt: {type: Boolean, default: true},
    showTool: {type: Boolean, default: true},
    canUpload: {type: Boolean, default: false},
    canDelete: {type: Boolean, default: false},
    multiple: {type: Boolean, default: true},
    client_id: {type: Number, default: null},
    lesson_id: {type: Number, default: null},
    training_event_id: {type: Number, default: null},
    training_event_of_client_id: {type: Number, default: null},
  },
  data() {
    return {
      isBusy: false,
      current_videos: [],
      percentageState: 0,
      embedUrlState: null,
      new_video: null,

      // ファイル進捗
      progre_set: [],
      file_index: 0,
      file_length: null,
      allProgre: 0,
    }
  },
  async mounted() {
    let self = this
    // 既存ドキュメント
    self.current_videos = _.cloneDeep(this.videos)
  },
  watch: {
    videos(val){
      this.current_videos = _.cloneDeep(val)
    },
  },
  computed: {
  },
  methods: {
    async getVideoInfo( file ){
      return new Promise(resolve => {
        // createElement("video") では正常動作しない
        const video = this.$refs.contentVideo
        // Safariで自動再生を有効にするためにミュートする
        video.muted = true;
        const videoInfo = {
          thumbnailSrc: undefined,
          duration: undefined,
        }
        video.onloadedmetadata = () => {
          videoInfo.duration = video.duration;

          // 明示的に再生を開始してからcurrentTimeを設定
          video.play().then(() => {

            // サムネイルを生成するために動画を少し進める
            video.currentTime = Math.min(0.5, video.duration);

            // currentTimeが変更された後に実行されるイベント
            video.onseeked = () => {
              const canvas = document.createElement("canvas");
              // 動画サイズが大きいとサムネイルも大きくなるので、縦横256px以内に変換しています。
              let width = video.videoWidth;
              let height = video.videoHeight;
              const maxSize = Math.max(width, height);
              const thumbnailMaxSize = 512; // 256;
              if( maxSize > thumbnailMaxSize ) {
                const rate = thumbnailMaxSize / maxSize;
                width *= rate;
                height *= rate;
              }
              canvas.width = width;
              canvas.height = height;
              const context = canvas.getContext("2d");
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const dataURL = canvas.toDataURL();
              videoInfo.thumbnailSrc = dataURL;
              resolve(videoInfo);
            }
          }).catch(error => {
            console.error("Error playing video:", error);
          });
        }
        video.src = URL.createObjectURL(file);
      });
    },
    async thumbnailUpload(file, thumbnailSrc, upload_info) {
      // プレビュー画像をサムネイルとしてアップロード
      const blob = await (await fetch(thumbnailSrc)).blob();
      const thumbnailFile = new File([blob], file.name + ".png", { type: blob.type });

      // エラー処理は呼び出し元でcatchする
      await axios({
        method: 'put',
        url: upload_info.thumbnail_presigned_url,
        data: thumbnailFile,
      });
    },

    async fileSelected(event){
      let self = this

      // ファイルタイプチェック
      let hasTypeOver = false
      for(let i = 0; i < event.target.files.length; i++){
        const file = event.target.files[i]
        if(!file.type.includes("video/")) hasTypeOver = true
      }
      if(hasTypeOver){
        window.storeCtl.commit("alert/setError", "動画以外はアップロード出来ません")
        self.files = []
        return 
      }

      // 容量チェック: 300mb
      let hasSizeOver = false
      for(let i = 0; i < event.target.files.length; i++){
        const file = event.target.files[i]
        if(file.size > 1024 * 1024 * 300) hasSizeOver = true
      }
      if(hasSizeOver){
        window.storeCtl.commit("alert/setError", "1つのファイルのサイズは500MB以下にしてください")
        self.files = []
        return 
      }

      // 開始
      self.isBusy = true
      // 進捗バー準備
      this.progre_set = []
      for(let i = 0; i < event.target.files.length; i++){
        this.progre_set.push(0)
      }
      this.file_length = event.target.files.length
      // アップロード
      for(let i = 0; i < event.target.files.length; i++){
        this.file_index = i + 1
        const file = event.target.files[i]
        await this.upload(file, i) 
      }
      self.isBusy = false
      // window.storeCtl.commit("alert/setSuccess", "動画のアップロードが完了しました。")
      self.$emit("videosUpdated", self.current_videos)

    },
    async upload(file, file_index){
      let self = this
      
      // アップロード情報を取得
      const upload_info = await self.generateUploadPresignedUrl()
      // ビデオのサムネイルと再生時間を取得
      const videoInfo = await self.getVideoInfo(file)
      // サムネイルのアップロード
      try {
        await self.thumbnailUpload(file, videoInfo.thumbnailSrc, upload_info);
      } catch (error) {
        // エラーハンドリング
        console.error('Upload Thumbnail Error:', error);
        window.storeCtl.commit("alert/setError", error.message)
        self.isBusy = false
        return;
      }

      // upload video
      await axios({
        method: 'put',
        url: upload_info.file_presigned_url,
        data: file,
        onUploadProgress: (progressEvent) => {
          // 進捗情報を取得
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${percentage}%`);
          this.percentageState = percentage;
          this.progre_set[file_index] = percentage
          this.updateAllProgre()
        },
      })
        .then(async (res) => {
          await self.videoInfoUpload(file, upload_info, videoInfo.duration)
        })
        .catch((error) => {
          // エラーハンドリング
          console.error('Upload Error:', error);
          self.isBusy = false
          window.storeCtl.commit("alert/setError", error.message)
        });
    },

    // アップロード用URL
    async generateUploadPresignedUrl(){
      return await httpClient
        .post(`/cmp/floor/videos/generate_upload_presigned_url.json`, {
          file_extension: "png"
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            console.log("generateUploadPresignedUrl",res.data.data.file_presigned_url)
            return res.data.data
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
            return false
          }
        })
    },

    async videoInfoUpload(file, upload_info, duration){
      let self = this
      // create
      await httpClient
        .post(`/cmp/floor/videos.json`, {
          video: {
            client_id: self.client_id,
            training_event_of_client_id: self.training_event_of_client_id,
            training_event_id: self.training_event_id,
            lesson_id: self.lesson_id,
            doc_type: self.doc_type,
            file_name: file.name,
            file_size: file.size,
            duration: duration,
            file_path: upload_info.file_path,
            thumbnail_file_path: upload_info.thumbnail_file_path,
          }
        })
        .then(async (res) => {
          if (res.data.status === 'success') {
            // 表示側に以降
            self.new_video = res.data.data.video
            self.current_videos.push(self.new_video)
          }else{
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },

    // 進捗
    updateAllProgre(){
      if(this.file_length == null || this.file_length == 0) return "-"
      this.allProgre = parseInt(_.sum(this.progre_set) / this.file_length)
    },

    //　ドキュメント削除後
    docDeleted(doc){
      // 生きているdoc_idsを引き数字
      this.current_videos = _.filter(this.current_videos, (d) => {return d.id !== doc.id})
      // let new_current_doc_ids = _.map(this.current_videos, 'id');
      this.$emit("videosUpdated", this.current_videos)
    },
  }
}
