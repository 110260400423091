
import _ from 'lodash';
import { mapState } from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      loading: null,
    }
  },
  async mounted() {
  },
  computed: {
    ...mapState({
    }),
    isLoading() {
      return this.$store.getters['loading/isLoading']; // OK
    },
  },
  methods: {
  },
}
