
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { scrollToTop } from '~/utils/tool'
import Common from '~/mixins/common'
import ClienMultiMessage from '~/components/common/client/MultiMessage'
import ClientMessage from '~/components/common/client/Message'

export default {
  mixins: [Common],
  components: {
    ClienMultiMessage,
    ClientMessage,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      showFilter: false,

      // data
      freeword: null,
      only_handle: false,
      created_at_gteq: null,
      created_at_lteq_end_of_day: null,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentAlerts: [],
    }
  },
  async mounted() {
    let self = this
    this.search()
  },
  computed: {
  },
  methods: {
    async clear() {
      this.only_handle = false
      this.created_at_gteq = null
      this.created_at_lteq_end_of_day = null
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this
      // set params
      let params = {
        "q[client_uid_or_client_name_cont]": self.freeword,
        "q[created_at_gteq]": self.created_at_gteq,
        "q[created_at_lteq_end_of_day]": self.created_at_lteq_end_of_day,
        only_handle: self.only_handle,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/alerts.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentAlerts = res.data.data.alerts
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
