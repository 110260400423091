
import _ from 'lodash';
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
  },
  data() {
    return {
      edit_memo: null,
      modal: false,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    memo(){
      if(this.$store.state.fireStore.data && this.$store.state.fireStore.data.memo) return this.$store.state.fireStore.data.memo
      return null
    },
  },
  methods: {
    openModal(){
      this.edit_memo = _.cloneDeep(this.memo)
      this.modal = true
    },
    update(){
      const doc = this.$fire.firestore.collection('companies').doc(window.storeCtl.state.auth.company.uid);
      this.$fire.firestore.collection("companies").doc(window.storeCtl.state.auth.company.uid).set({
          memo: this.edit_memo,
        }, { merge: true }).then(response => {
          // 更新成功時の処理
          console.log("firestore update", response)
          this.modal = false
        }).catch(error => {
          // 更新失敗時の処理
        })

    },
  }
}
