import { httpClient } from '~/utils/axios'
import _ from 'lodash'

export default {
  data() {
    return {
      clients: []
    }
  },
  async mounted() {},
  computed: {
    formatedClients() {
      let clients = _.cloneDeep(this.clients)
      clients.forEach(cl => {
        let label = cl.name
        if (cl.name_kana) label = `${label} / ${cl.name_kana}`
        if (cl.account_id) label = `${label} / ${cl.account_id}`
        if (cl.headline) label = `${label} / ${cl.headline}`
        if (cl.phone_number) label = `${label} / ${cl.phone_number}`
        if (cl.status === 'active') {
          if (cl.reserve_type === 'unlimit')
            label = `${label} / 無制限:${cl.reserved_lesson_size}中`
          if (cl.reserve_type === 'stock')
            label = `${label} / チケット簡易:${cl.stock_lesson_size}`
          if (cl.reserve_type === 'stock_limit')
            label = `${label} / チケット厳密:${cl.stock_lesson_size}`
          if (cl.reserve_type === 'monthly_stock')
            label = `${label} / 月${cl.monthly_stock_lesson_size}回:${cl.stock_lesson_size},${cl.stock_lesson_size_next},${cl.stock_lesson_size_next2}`
          if (cl.reserve_type === 'weekly_limit')
            label = `${label} / 週${cl.monthly_stock_lesson_size}回:${cl.reserved_lesson_size}中`
        } else if (cl.status === 'trial') {
          label = `${label} / 体験:${cl.reserved_lesson_size}中`
        }
        if (cl.base_menu_id) {
          let selectedMenu = _.find(this.menus, ['id', cl.base_menu_id])
          if (selectedMenu) label = `${label} / ${selectedMenu.name}`
        }
        cl.label = label
        cl.code = cl.id
      })
      clients = _.orderBy(clients, ['name_kana', 'id'], ['asc', 'asc'])
      return clients
    },
    ticketFormatedClients() {
      let clients = _.cloneDeep(this.formatedClients)
      return _.filter(clients, c => {
        return (
          c.status === 'active' &&
          ['stock', 'stock_limit'].includes(c.reserve_type)
        )
      })
    }
  },
  methods: {
    async getClients(type = 'alive') {
      await httpClient
        .get(
          `/cmp/floor/clients/index_light.json?${type === 'alive' ? 'status_eq_any=trial,active' : null}`
        )
        .then(async res => {
          if (res.data.status === 'success') {
            this.clients = res.data.data.clients
          } else {
            window.storeCtl.commit('alert/setError', res.data.message)
          }
        })
        .finally(() => {})
    }
  }
}
