
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { scrollToTop, sleep } from '~/utils/tool'
import BosyScanDetail from '~/components/common/bodyscan/Detail'
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  mixins: [Common,Masters],
  components: {
    BosyScanDetail,
    VueQrcode,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      // data
      freeword: null,
      status_eq_any: ["before","inprocess","success"],
      bodyscanStatusOptions: [
        { text: '処理前', value: "before" },
        { text: '処理中', value: "inprocess" },
        { text: '成功', value: "success" },
        { text: '失敗', value: "fail" },
        { text: '有効期限切れ', value: "expired" },
      ],
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentBodyscans: [],

      // sort
      statusFilters: [],
      over_size: 0,
      order: "desc",
      searchText: null,

      // modal
      showPaymentModal: false,
      edit_count: 0,
      edit_client_id: null,

      // bodygram
      showQrModal: false,
      orgId: "org_17UaeQO5IJXDGwgwfSVk5s",
      selectedBodyScan: null,
      scanUrl: null,

      // client
      showClientModal: false,

    }
  },
  async mounted() {
    let self = this
    // filter
    if(self.filter) self.status_eq_any = [self.filter]
    this.search()
  },
  // destroyed: function () {
  //   clearInterval(this.closeTimer)
  // },
  computed: {
  },
  methods: {
    // bodyscan
    // 1.状態を取得
    // 2.成功以外なら再実行
    async scanStart(bodyscan){
      let self = this
      window.storeCtl.commit("loading/start")
      let res_bodyscan = await self.getScanResult(bodyscan)
      // 成功なら何もしない
      if(res_bodyscan.status === "success") return
      // それ以外なら初回フローからやり直し
      this.selectedBodyScan = bodyscan
      await httpClient
        .put(`/cmp/floor/bodyscans/${bodyscan.uid}/create_session.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.replaceScanData(res.data.data.bodyscan)
            const token = res.data.data.bodyscan.api_session_id
            // console.log("token",token)
            this.scanUrl = `https://platform.bodygram.com/ja/${this.orgId}/scan?token=${token}&system=metric`
            if(this.isPC){
              // ここでQR出す
              this.showQrModal = true
            }else{
              setTimeout(() => {
                const w = window.open(this.scanUrl)
                w.addEventListener('unload', () => {
                  if(w.location.href==='about:blank') return;
                  console.log('unload');
                  self.getScanResult(bodyscan, true)
                }, false);
                // this.closeTimer = setInterval(() => {
                //   if(!w.closed) return;
                //   clearInterval(this.closeTimer);
                //   console.log('closed');
                //   this.getScanResult(bodyscan)
                // }, 500);
              });
            }
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    // 結果取得
    async getScanResult(bodyscan, loading = false){
      if(loading) window.storeCtl.commit("loading/start")
      return await httpClient
        .put(`/cmp/floor/bodyscans/${bodyscan.uid}/get_result.json`)
        .then(async (res) => {
          if(loading) window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            let new_bodyscan = res.data.data.bodyscan
            // アラート
            if(loading){
              if(new_bodyscan.status === "success" && this.canShowResult(new_bodyscan) === false){
                window.storeCtl.commit("alert/setError", "スキャンは完了していますが、結果データを処理中です")
              }
              if(!new_bodyscan.scan_at){
                window.storeCtl.commit("alert/setError", "スキャン結果が見つかりません")
              }
              if(this.canShowResult(new_bodyscan) === true) this.$refs.bodyscan.showDetail(new_bodyscan)
            }
            this.replaceScanData(res.data.data.bodyscan)
            // qr閉じる
            this.showQrModal = false
            return res.data.data.bodyscan
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return null
          }
        })
    },
    async getScanResultCrrent(){
      await this.getScanResult(this.selectedBodyScan, true)
    },
    // データ入れ替え
    replaceScanData(bodyscan){
      console.log("bodyscan", bodyscan)
      this.search(false)
    },

    // 購入modal
    openModal(){
      this.getClients()
      this.edit_count = 1
      this.edit_client_id = this.client ? this.client.id : null
      this.showPaymentModal = true
    },
    async scanBuy(){
      let self = this
      // set params
      let params = {
        client_id: self.edit_client_id,
        count: self.edit_count,
      }
      window.storeCtl.commit("loading/start", true)
      await httpClient
      .post('/cmp/floor/bodyscans.json', params)
      .then(async (res) => {
        if (res.data.status === 'success') {
          scrollToTop()
          self.showPaymentModal = false
          window.storeCtl.commit("alert/setSuccess", "スキャン枠を購入しました")
          setTimeout(()=>{
            self.autoSearch()
          }, 500)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },

    // 顧客変更modal
    editClient(bodyscan){
      this.getClients()
      this.selectedBodyScan = bodyscan
      this.edit_client_id = null
      this.showClientModal = true
    },
    async updateClient(){
      let self = this
      // set params
      let params = {
        client_id: self.edit_client_id,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .put(`/cmp/floor/bodyscans/${self.selectedBodyScan.uid}/update_client.json`, params)
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.showClientModal = false
          window.storeCtl.commit("alert/setSuccess", "顧客をセットしました")
          setTimeout(()=>{
            self.autoSearch()
          }, 500)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },

    canGetResult(bodyscan){
      if(bodyscan.status === 'inprocess') return true
      if(bodyscan.status === 'success'){
        if(!bodyscan.api_result) return true
        if(!bodyscan.api_result.entry) return true
        if(!bodyscan.api_result.entry.id) return true
      }
      return false
    },
    canShowResult(bodyscan){
      if(bodyscan.status === 'success' && bodyscan.api_result && bodyscan.api_result.entry && bodyscan.api_result.entry.id) return true
      return false
    },

    // search
    async clear() {
      this.freeword = null
      this.status_eq_any = []
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(loading = true){
      let self = this

      // set params
      let params = {
        "q[client_name_cont]": self.freeword,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        order: self.order,
        page: self.pages.current_page,
      }
      if(loading) window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/bodyscans.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentBodyscans = res.data.data.bodyscans
          self.pages = res.data.data.pages
          if(loading) scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        if(loading) window.storeCtl.commit("loading/stop")
      })
    },
  },
}
