
import { Line } from 'vue-chartjs'
// import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';
import dayjs from 'dayjs';

export default {
  extends: Line,
  props: {
    trends: Array,
  },
  components: {
    // ChartJsPluginDataLabels,
  },
  computed: {
  },
  watch: {
    trends: function (trends) {
      // console.log("trends watch",trends)
      this.updateChart(trends)
    },
  },
  mounted() {
    this.updateChart(this.trends);
  },
  data: function () {
    return {
      chartdata: {
        labels: [],
        datasets: [
          {
            yAxisID: "point",
            lineTension: 0,
            type: 'line',
            barPercentage: 0.3,
            label: 'レビュー',
            backgroundColor: '#ffe29e',
            borderColor: '#ffe29e',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
          },
          {
            yAxisID: "point",
            lineTension: 0,
            type: 'line',
            label: '筋肉痛',
            backgroundColor: '#c2d2ff',
            borderColor: '#c2d2ff',
            fill: false,
            data: [],
            spanGaps: true, //値が抜け落ちていた時、埋めるか否か
          },
        ]
      },
      options: {
        legend : {
          // display : false,
          position: "bottom", 
          labels: {
            fontSize: 10,
          },
          // 'onClick' : function (evt, item) {
          //   console.log ('legend onClick', evt, item);
          // },
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: { /* ここです */
          callbacks: {
            label: function(tooltipItem, data){
              // this.trendsが取れない
              // console.log("tooltips",tooltipItem,data)
              // console.log("date",this.trends,tooltipItem.label)
              // let trend = _.find(this.trends, ['date', tooltipItem.label])
              // console.log("trend",trend)
              // let message = `${tooltipItem.yLabel} / 食事：${trend.food} / 睡眠：${trend.sleep}`
              // return message
              return tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: false,
              labelString: '日付'
            },
            ticks : {
              minRotation: 0,
              maxRotation: 0,
              autoSkip: true,
              maxTicksLimit: 4,
              callback: function(tick, index) {
                let str = dayjs(tick).format("YY/MM/DD");
                // let isRetern = false
                // if(index === 0) isRetern = true
                // if((index + 1) % 5 === 0) isRetern = true
                // return isRetern ? str : "";
                return str
              }
            }
          }],
          yAxes: [
            {
              id: "point",
              type: "linear",
              position: "left",
              ticks: {
                min: 0,
                max: 5,
                beginAtZero: true,
                // maxTicksLimit: 5,
                stepSize: 1,
                callback: function(label, index, labels) { /* ここです */
                  return ((label % 1) == 0) ? label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                }
              }
            },
          ]
        },
      }
    }
  },
  methods: {
    updateChart(trends){
      console.log("review trends",trends)
      trends = _.orderBy(trends, 'date', 'asc');
      // データ加工
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      this.chartdata.datasets[1].data = []
      trends.forEach(tr => {
        this.chartdata.labels.push(tr.date)
        this.chartdata.datasets[0].data.push(tr.review_rate)
        this.chartdata.datasets[1].data.push(tr.muscle_pain_rate)
      })
      console.log("review trends",this.chartdata)      
      console.log("review trends",this.options)      

      // 設定
      this.options.scales.xAxes[0].ticks.maxTicksLimit = this.$store.state.devise.isPC ? 10 : 4

      this.renderChart(this.chartdata, this.options)

    },
  }
}
