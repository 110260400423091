
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { scrollToTop } from '~/utils/tool'
import { taskStatusOptions } from '~/utils/models'
import TaskStatusChip from '~/components/common/task/StatusChip'
import TaskEdit from '~/components/common/task/Edit'

export default {
  components: {
    TaskStatusChip,
    TaskEdit,
  },
  props: {
    client: {type: Object, default: null},
    filter: {type: String, default: null},
    showClient: {type: Boolean, default: false},
  },
  data() {
    return {
      showFilter: false,

      // data
      freeword: null,
      status_eq_any: [],
      taskStatusOptions: taskStatusOptions,

      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      currentTasks: [],
    }
  },
  async mounted() {
    let self = this
    this.status_eq_any = ["inprogress"]
    this.search()
  },
  computed: {
  },
  methods: {
    async clear() {
      this.freeword = null
      this.status_eq_any = ["inprogress"]
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this
      // set params
      let params = {
        "q[client_uid_or_client_name_or_title_or_memo_cont]": self.freeword,
        "status_eq_any": self.status_eq_any,
        client_id: self.client ? self.client.id : null,
        page: self.pages.current_page,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/tasks.json', {params: params})
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.currentTasks = res.data.data.tasks
          self.pages = res.data.data.pages
          scrollToTop()
          self.$emit("changeSearch", res.data.data.pages)
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
