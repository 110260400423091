
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import Training from '~/mixins/training'
import { client_training_event, training_event_status } from '~/utils/models'
import _ from 'lodash';

export default {
  mixins: [Common,Training],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showEditLog: false,
      edit_training_event: null,
      training_event_status: training_event_status,
      client_training_events: [],
    }
  },
  async mounted() {
    this.client_training_events = _.cloneDeep(this.client.client_training_events)
  },
  computed: {
  },
  watch: {
    client (val, old) {
      this.client_training_events = _.cloneDeep(val.client_training_events) 
    }
  },  
  methods: {
    trainingEventLog(training_event){
      let current_client_training_event = _.find(this.client_training_events, ["training_event_id", training_event.id])
      if(!current_client_training_event) current_client_training_event = _.cloneDeep(client_training_event)
      return current_client_training_event
    },
    openLogEdit(training_event){
      this.edit_training_event = training_event
      this.edit_client_training_event = _.cloneDeep(this.trainingEventLog(training_event))
      this.showEditLog = true
    },
    async updateLog(){
      let self = this
      // 送信
      window.storeCtl.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/client_training_events/${self.edit_client_training_event.uid}.json`, {
          client_training_event: self.edit_client_training_event,
          clinet_id: self.client.id,
          training_event_id: self.edit_training_event.id,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            let new_client_training_events = []
            // データ入れ替え
            let client_training_event_blank = true
            self.client_training_events.forEach(cte => {
              if(cte.training_event_id === res.data.data.client_training_event.training_event_id){
                new_client_training_events.push(res.data.data.client_training_event)
                client_training_event_blank = false
              }else{
                new_client_training_events.push(cte)
              }
            })
            if(client_training_event_blank) new_client_training_events.push(res.data.data.client_training_event)
            self.client_training_events = new_client_training_events
            self.showEditLog = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
        })
    },
  },
}
