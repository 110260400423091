
import dayjs from 'dayjs';
import axios from "axios";
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import { ModelObj, ModelGltf } from 'vue-3d-model';
import _ from 'lodash';

export default {
  mixins: [Common],
  components: {
    ModelObj,
    ModelGltf,
  },
  props: {
  },
  data() {
    return {
      bodyscan: null,
      result_obj: null,
      cameraPosition: {x: 0, y: 0, z: 2.5},
      backgroundColor: "0xffffff", // "#ebebeb",
      showModal: false,
      modelWidth: 0,
      modelHeight: 0,
      controlsOptions: {
        enablePan: false,
        enableZoom: false,
        enableRotate: true,
      },
      lights: [
        // https://threejs.org/docs/#api/en/lights/AmbientLight
        {
          type: 'AmbientLight',
          position: { x: 0, y: 0, z: 0 },
          color: 0xffffff,
          intensity: 0.5
        },
        // https://threejs.org/docs/#api/en/lights/PointLight
        {
          type: 'PointLight',
          position: { x: 1, y: 1, z: 1 },
          color: 0xffffff,
          intensity: 0.5,
          distance: 5,
        },
        {
          type: 'PointLight',
          position: { x: -1, y: 1, z: -1 },
          color: 0xffffff,
          intensity: 0.5,
          distance: 5,
        },
        // https://threejs.org/docs/#api/en/lights/HemisphereLight
        {
          type: 'HemisphereLight',
          position: { x: 0, y: 0, z: 0 },
          skyColor: 0x2F54EB,
          groundColor: 0x000000,
          intensity: 0.5
        },
      ],
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    objFile(){
      let data = atob(this.result_obj.entry.avatar.data)
      const blob = new Blob([data], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      return url
    },
    height(){
      return parseInt(this.result_obj.entry.input.photoScan.height / 10)
    },
    weight(){
      return parseInt(this.result_obj.entry.input.photoScan.weight / 1000)
    },
    scanAt(){
      return dayjs(this.bodyscan.scan_at).format("YYYY/MM/DD HH:mm:ss")
    },
    sex(){
      return this.result_obj.entry.input.photoScan.gender === "male" ? "male" : "female"
    },
    sex_text(){
      return this.result_obj.entry.input.photoScan.gender === "male" ? "男性" : "女性"
    },
  },
  methods: {
    async showDetail(bodyscan){
      window.storeCtl.commit("loading/start")
      await httpClient
        .get(`/cmp/floor/bodyscans/${bodyscan.uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            this.bodyscan = res.data.data.bodyscan
            this.result_obj = await this.fetchJson(this.bodyscan.result_obj.url)
            this.showModal = true
            setTimeout(()=>{
              let elem = document.querySelector('#bodyscan_modal .avator_wrapper')
              this.modelWidth = elem.clientWidth - 50
              this.modelHeight = window.innerHeight - 400 // 150
            }, 500)
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    getSize(key){
      let size = _.find(this.result_obj.entry.measurements, ["name", key])
      return _.round((size.value / 10), 1)
    },
    async fetchJson(url) {
      // console.log("fetchJson",url)
      try {
        const response = await axios.get(url);
        const json = response.data;  // response.dataにJSONの内容が格納されています
        console.log("json",json)
        return json;
      } catch (error) {
        console.log("error",error)
        window.storeCtl.commit("alert/setError", "データの取得に失敗しました")
      }
    }
  }
}
