export const CommonAccountInfoModal = () => import('../../components/common/AccountInfoModal.vue' /* webpackChunkName: "components/common-account-info-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonModalFirstContact = () => import('../../components/common/ModalFirstContact.vue' /* webpackChunkName: "components/common-modal-first-contact" */).then(c => wrapFunctional(c.default || c))
export const CommonMultiAuth = () => import('../../components/common/MultiAuth.vue' /* webpackChunkName: "components/common-multi-auth" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilCalucurator = () => import('../../components/util/Calucurator.vue' /* webpackChunkName: "components/util-calucurator" */).then(c => wrapFunctional(c.default || c))
export const UtilCard = () => import('../../components/util/Card.vue' /* webpackChunkName: "components/util-card" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilHint = () => import('../../components/util/Hint.vue' /* webpackChunkName: "components/util-hint" */).then(c => wrapFunctional(c.default || c))
export const UtilIconAvatar = () => import('../../components/util/IconAvatar.vue' /* webpackChunkName: "components/util-icon-avatar" */).then(c => wrapFunctional(c.default || c))
export const UtilImageFileInput = () => import('../../components/util/ImageFileInput.vue' /* webpackChunkName: "components/util-image-file-input" */).then(c => wrapFunctional(c.default || c))
export const UtilImageThumbnail = () => import('../../components/util/ImageThumbnail.vue' /* webpackChunkName: "components/util-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const UtilInputColor = () => import('../../components/util/InputColor.vue' /* webpackChunkName: "components/util-input-color" */).then(c => wrapFunctional(c.default || c))
export const UtilInputTime = () => import('../../components/util/InputTime.vue' /* webpackChunkName: "components/util-input-time" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))
export const UtilNumberInput = () => import('../../components/util/NumberInput.vue' /* webpackChunkName: "components/util-number-input" */).then(c => wrapFunctional(c.default || c))
export const UtilPageHeader = () => import('../../components/util/PageHeader.vue' /* webpackChunkName: "components/util-page-header" */).then(c => wrapFunctional(c.default || c))
export const UtilQrReader = () => import('../../components/util/QrReader.vue' /* webpackChunkName: "components/util-qr-reader" */).then(c => wrapFunctional(c.default || c))
export const UtilRating = () => import('../../components/util/Rating.vue' /* webpackChunkName: "components/util-rating" */).then(c => wrapFunctional(c.default || c))
export const UtilSideMenu = () => import('../../components/util/SideMenu.vue' /* webpackChunkName: "components/util-side-menu" */).then(c => wrapFunctional(c.default || c))
export const UtilTabs = () => import('../../components/util/Tabs.vue' /* webpackChunkName: "components/util-tabs" */).then(c => wrapFunctional(c.default || c))
export const UtilTouchInput = () => import('../../components/util/TouchInput.vue' /* webpackChunkName: "components/util-touch-input" */).then(c => wrapFunctional(c.default || c))
export const UtilTour = () => import('../../components/util/Tour.vue' /* webpackChunkName: "components/util-tour" */).then(c => wrapFunctional(c.default || c))
export const UtilUserIcon = () => import('../../components/util/UserIcon.vue' /* webpackChunkName: "components/util-user-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonAlertLists = () => import('../../components/common/alert/Lists.vue' /* webpackChunkName: "components/common-alert-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonBodyscanDetail = () => import('../../components/common/bodyscan/Detail.vue' /* webpackChunkName: "components/common-bodyscan-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonBodyscanLists = () => import('../../components/common/bodyscan/Lists.vue' /* webpackChunkName: "components/common-bodyscan-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonChatForm = () => import('../../components/common/chat/Form.vue' /* webpackChunkName: "components/common-chat-form" */).then(c => wrapFunctional(c.default || c))
export const CommonChatMessage = () => import('../../components/common/chat/Message.vue' /* webpackChunkName: "components/common-chat-message" */).then(c => wrapFunctional(c.default || c))
export const CommonChatReactionBtn = () => import('../../components/common/chat/ReactionBtn.vue' /* webpackChunkName: "components/common-chat-reaction-btn" */).then(c => wrapFunctional(c.default || c))
export const CommonChatRoom = () => import('../../components/common/chat/Room.vue' /* webpackChunkName: "components/common-chat-room" */).then(c => wrapFunctional(c.default || c))
export const CommonClPaymentCalucurator = () => import('../../components/common/cl_payment/Calucurator.vue' /* webpackChunkName: "components/common-cl-payment-calucurator" */).then(c => wrapFunctional(c.default || c))
export const CommonClPaymentKaikei = () => import('../../components/common/cl_payment/Kaikei.vue' /* webpackChunkName: "components/common-cl-payment-kaikei" */).then(c => wrapFunctional(c.default || c))
export const CommonClPaymentLists = () => import('../../components/common/cl_payment/Lists.vue' /* webpackChunkName: "components/common-cl-payment-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonClPaymentNew = () => import('../../components/common/cl_payment/New.vue' /* webpackChunkName: "components/common-cl-payment-new" */).then(c => wrapFunctional(c.default || c))
export const CommonClientActionMenu = () => import('../../components/common/client/ActionMenu.vue' /* webpackChunkName: "components/common-client-action-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonClientCard = () => import('../../components/common/client/Card.vue' /* webpackChunkName: "components/common-client-card" */).then(c => wrapFunctional(c.default || c))
export const CommonClientCustomInput = () => import('../../components/common/client/CustomInput.vue' /* webpackChunkName: "components/common-client-custom-input" */).then(c => wrapFunctional(c.default || c))
export const CommonClientDetail = () => import('../../components/common/client/Detail.vue' /* webpackChunkName: "components/common-client-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonClientEdit = () => import('../../components/common/client/Edit.vue' /* webpackChunkName: "components/common-client-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonClientEditCourse = () => import('../../components/common/client/EditCourse.vue' /* webpackChunkName: "components/common-client-edit-course" */).then(c => wrapFunctional(c.default || c))
export const CommonClientEditMemo = () => import('../../components/common/client/EditMemo.vue' /* webpackChunkName: "components/common-client-edit-memo" */).then(c => wrapFunctional(c.default || c))
export const CommonClientInvitationChip = () => import('../../components/common/client/InvitationChip.vue' /* webpackChunkName: "components/common-client-invitation-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonClientJoin = () => import('../../components/common/client/Join.vue' /* webpackChunkName: "components/common-client-join" */).then(c => wrapFunctional(c.default || c))
export const CommonClientListCard = () => import('../../components/common/client/ListCard.vue' /* webpackChunkName: "components/common-client-list-card" */).then(c => wrapFunctional(c.default || c))
export const CommonClientListTr = () => import('../../components/common/client/ListTr.vue' /* webpackChunkName: "components/common-client-list-tr" */).then(c => wrapFunctional(c.default || c))
export const CommonClientMenu = () => import('../../components/common/client/Menu.vue' /* webpackChunkName: "components/common-client-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonClientMessage = () => import('../../components/common/client/Message.vue' /* webpackChunkName: "components/common-client-message" */).then(c => wrapFunctional(c.default || c))
export const CommonClientMultiMessage = () => import('../../components/common/client/MultiMessage.vue' /* webpackChunkName: "components/common-client-multi-message" */).then(c => wrapFunctional(c.default || c))
export const CommonClientNew = () => import('../../components/common/client/New.vue' /* webpackChunkName: "components/common-client-new" */).then(c => wrapFunctional(c.default || c))
export const CommonClientOtherCompanyChip = () => import('../../components/common/client/OtherCompanyChip.vue' /* webpackChunkName: "components/common-client-other-company-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartAuth = () => import('../../components/common/client/PartAuth.vue' /* webpackChunkName: "components/common-client-part-auth" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartChart = () => import('../../components/common/client/PartChart.vue' /* webpackChunkName: "components/common-client-part-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartCrm = () => import('../../components/common/client/PartCrm.vue' /* webpackChunkName: "components/common-client-part-crm" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartDate = () => import('../../components/common/client/PartDate.vue' /* webpackChunkName: "components/common-client-part-date" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartLesson = () => import('../../components/common/client/PartLesson.vue' /* webpackChunkName: "components/common-client-part-lesson" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartMenu = () => import('../../components/common/client/PartMenu.vue' /* webpackChunkName: "components/common-client-part-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartSubsc = () => import('../../components/common/client/PartSubsc.vue' /* webpackChunkName: "components/common-client-part-subsc" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartTask = () => import('../../components/common/client/PartTask.vue' /* webpackChunkName: "components/common-client-part-task" */).then(c => wrapFunctional(c.default || c))
export const CommonClientPartTicket = () => import('../../components/common/client/PartTicket.vue' /* webpackChunkName: "components/common-client-part-ticket" */).then(c => wrapFunctional(c.default || c))
export const CommonClientProfile = () => import('../../components/common/client/Profile.vue' /* webpackChunkName: "components/common-client-profile" */).then(c => wrapFunctional(c.default || c))
export const CommonClientReserveSummary = () => import('../../components/common/client/ReserveSummary.vue' /* webpackChunkName: "components/common-client-reserve-summary" */).then(c => wrapFunctional(c.default || c))
export const CommonClientSearch = () => import('../../components/common/client/Search.vue' /* webpackChunkName: "components/common-client-search" */).then(c => wrapFunctional(c.default || c))
export const CommonClientStatusChip = () => import('../../components/common/client/StatusChip.vue' /* webpackChunkName: "components/common-client-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTaskChip = () => import('../../components/common/client/TaskChip.vue' /* webpackChunkName: "components/common-client-task-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrainingEvents = () => import('../../components/common/client/TrainingEvents.vue' /* webpackChunkName: "components/common-client-training-events" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendChart = () => import('../../components/common/client_trend/Chart.vue' /* webpackChunkName: "components/common-client-trend-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendChartBody = () => import('../../components/common/client_trend/ChartBody.vue' /* webpackChunkName: "components/common-client-trend-chart-body" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendForm = () => import('../../components/common/client_trend/Form.vue' /* webpackChunkName: "components/common-client-trend-form" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendList = () => import('../../components/common/client_trend/List.vue' /* webpackChunkName: "components/common-client-trend-list" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendLists = () => import('../../components/common/client_trend/Lists.vue' /* webpackChunkName: "components/common-client-trend-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendListsModal = () => import('../../components/common/client_trend/ListsModal.vue' /* webpackChunkName: "components/common-client-trend-lists-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendReport = () => import('../../components/common/client_trend/Report.vue' /* webpackChunkName: "components/common-client-trend-report" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyBoard = () => import('../../components/common/company/Board.vue' /* webpackChunkName: "components/common-company-board" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyBoardEdit = () => import('../../components/common/company/BoardEdit.vue' /* webpackChunkName: "components/common-company-board-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyChartSales = () => import('../../components/common/company/ChartSales.vue' /* webpackChunkName: "components/common-company-chart-sales" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyGroupChip = () => import('../../components/common/company_group/Chip.vue' /* webpackChunkName: "components/common-company-group-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendChartCalendar = () => import('../../components/common/company_trend/ChartCalendar.vue' /* webpackChunkName: "components/common-company-trend-chart-calendar" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendChartLesson = () => import('../../components/common/company_trend/ChartLesson.vue' /* webpackChunkName: "components/common-company-trend-chart-lesson" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendChartMenu = () => import('../../components/common/company_trend/ChartMenu.vue' /* webpackChunkName: "components/common-company-trend-chart-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendChartPaymentMethod = () => import('../../components/common/company_trend/ChartPaymentMethod.vue' /* webpackChunkName: "components/common-company-trend-chart-payment-method" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendChartSales = () => import('../../components/common/company_trend/ChartSales.vue' /* webpackChunkName: "components/common-company-trend-chart-sales" */).then(c => wrapFunctional(c.default || c))
export const CommonCompanyTrendDashboard = () => import('../../components/common/company_trend/Dashboard.vue' /* webpackChunkName: "components/common-company-trend-dashboard" */).then(c => wrapFunctional(c.default || c))
export const CommonDocList = () => import('../../components/common/doc/List.vue' /* webpackChunkName: "components/common-doc-list" */).then(c => wrapFunctional(c.default || c))
export const CommonDocLists = () => import('../../components/common/doc/Lists.vue' /* webpackChunkName: "components/common-doc-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermDiffPart = () => import('../../components/common/goal_term/DiffPart.vue' /* webpackChunkName: "components/common-goal-term-diff-part" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermEdit = () => import('../../components/common/goal_term/Edit.vue' /* webpackChunkName: "components/common-goal-term-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermList = () => import('../../components/common/goal_term/List.vue' /* webpackChunkName: "components/common-goal-term-list" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermLists = () => import('../../components/common/goal_term/Lists.vue' /* webpackChunkName: "components/common-goal-term-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermStatusChip = () => import('../../components/common/goal_term/StatusChip.vue' /* webpackChunkName: "components/common-goal-term-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonBodyData = () => import('../../components/common/lesson/BodyData.vue' /* webpackChunkName: "components/common-lesson-body-data" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonCard = () => import('../../components/common/lesson/Card.vue' /* webpackChunkName: "components/common-lesson-card" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChart = () => import('../../components/common/lesson/Chart.vue' /* webpackChunkName: "components/common-lesson-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChartBody = () => import('../../components/common/lesson/ChartBody.vue' /* webpackChunkName: "components/common-lesson-chart-body" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChartMuscle = () => import('../../components/common/lesson/ChartMuscle.vue' /* webpackChunkName: "components/common-lesson-chart-muscle" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChartReview = () => import('../../components/common/lesson/ChartReview.vue' /* webpackChunkName: "components/common-lesson-chart-review" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonEdit = () => import('../../components/common/lesson/Edit.vue' /* webpackChunkName: "components/common-lesson-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonEventSet = () => import('../../components/common/lesson/EventSet.vue' /* webpackChunkName: "components/common-lesson-event-set" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonLists = () => import('../../components/common/lesson/Lists.vue' /* webpackChunkName: "components/common-lesson-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonMenu = () => import('../../components/common/lesson/Menu.vue' /* webpackChunkName: "components/common-lesson-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonOtherCompanyChip = () => import('../../components/common/lesson/OtherCompanyChip.vue' /* webpackChunkName: "components/common-lesson-other-company-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonPartPrice = () => import('../../components/common/lesson/PartPrice.vue' /* webpackChunkName: "components/common-lesson-part-price" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonStatusChip = () => import('../../components/common/lesson/StatusChip.vue' /* webpackChunkName: "components/common-lesson-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTrainingEventSelector = () => import('../../components/common/lesson/TrainingEventSelector.vue' /* webpackChunkName: "components/common-lesson-training-event-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTrainingEvents = () => import('../../components/common/lesson/TrainingEvents.vue' /* webpackChunkName: "components/common-lesson-training-events" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTypeChip = () => import('../../components/common/lesson/TypeChip.vue' /* webpackChunkName: "components/common-lesson-type-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonPointLists = () => import('../../components/common/point/Lists.vue' /* webpackChunkName: "components/common-point-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonReportMenu = () => import('../../components/common/report/Menu.vue' /* webpackChunkName: "components/common-report-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonReservationAttendance = () => import('../../components/common/reservation/Attendance.vue' /* webpackChunkName: "components/common-reservation-attendance" */).then(c => wrapFunctional(c.default || c))
export const CommonReservationBulkNew = () => import('../../components/common/reservation/BulkNew.vue' /* webpackChunkName: "components/common-reservation-bulk-new" */).then(c => wrapFunctional(c.default || c))
export const CommonReservationEdit = () => import('../../components/common/reservation/Edit.vue' /* webpackChunkName: "components/common-reservation-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonReservationNew = () => import('../../components/common/reservation/New.vue' /* webpackChunkName: "components/common-reservation-new" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffEdit = () => import('../../components/common/staff/Edit.vue' /* webpackChunkName: "components/common-staff-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffList = () => import('../../components/common/staff/List.vue' /* webpackChunkName: "components/common-staff-list" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffMessage = () => import('../../components/common/staff/Message.vue' /* webpackChunkName: "components/common-staff-message" */).then(c => wrapFunctional(c.default || c))
export const CommonStaffSelector = () => import('../../components/common/staff/Selector.vue' /* webpackChunkName: "components/common-staff-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonSurveyAnswers = () => import('../../components/common/survey/Answers.vue' /* webpackChunkName: "components/common-survey-answers" */).then(c => wrapFunctional(c.default || c))
export const CommonSurveyQuestion = () => import('../../components/common/survey/Question.vue' /* webpackChunkName: "components/common-survey-question" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskEdit = () => import('../../components/common/task/Edit.vue' /* webpackChunkName: "components/common-task-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskLists = () => import('../../components/common/task/Lists.vue' /* webpackChunkName: "components/common-task-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTaskStatusChip = () => import('../../components/common/task/StatusChip.vue' /* webpackChunkName: "components/common-task-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonTicketSetLists = () => import('../../components/common/ticket_set/Lists.vue' /* webpackChunkName: "components/common-ticket-set-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingEventEdit = () => import('../../components/common/training_event/Edit.vue' /* webpackChunkName: "components/common-training-event-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListEdit = () => import('../../components/common/training_list/Edit.vue' /* webpackChunkName: "components/common-training-list-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListLists = () => import('../../components/common/training_list/Lists.vue' /* webpackChunkName: "components/common-training-list-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListSelector = () => import('../../components/common/training_list/Selector.vue' /* webpackChunkName: "components/common-training-list-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonVideoLists = () => import('../../components/common/video/Lists.vue' /* webpackChunkName: "components/common-video-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonVideoPlayer = () => import('../../components/common/video/Player.vue' /* webpackChunkName: "components/common-video-player" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocument = () => import('../../components/util/docs/Document.vue' /* webpackChunkName: "components/util-docs-document" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocumentInputs = () => import('../../components/util/docs/DocumentInputs.vue' /* webpackChunkName: "components/util-docs-document-inputs" */).then(c => wrapFunctional(c.default || c))
export const UtilVideosVideoInput = () => import('../../components/util/videos/VideoInput.vue' /* webpackChunkName: "components/util-videos-video-input" */).then(c => wrapFunctional(c.default || c))
export const UtilVideosVideoInputs = () => import('../../components/util/videos/VideoInputs.vue' /* webpackChunkName: "components/util-videos-video-inputs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
