import { mapState, mapGetters } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import axios from "axios";

export default {
  data() {
    return {
    }
  },
  async mounted() {
    let self = this

    // ページトップへ
    document.querySelector('body').scrollTop = 0

    // detect deviseit
    if (self.$mq === 'pc') {
      this.$store.commit('devise/setDrawer', true)
      this.$store.commit('devise/setIsPC', true)
    } else {
      this.$store.commit('devise/setIsPC', false)
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'isOwner']),
    ...mapState({
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isIPad: state => state.devise.isIPad,
      isTouchDevice: state => state.devise.isTouchDevice,
      new_alert_size: state => state.auth.new_alert_size,
      headquarter: state => state.auth.headquarter,
      company: state => state.auth.company,
      staff: state => state.auth.staff,
      settings: state => state.auth.settings,
      companies: state => state.company.companies,
      staffs: state => state.company.staffs,
      calendars: state => state.company.calendars,
      resources: state => state.company.resources,
      courses: state => state.company.courses,
      menus: state => state.company.menus,
      sections: state => state.company.sections,
      payment_methods: state => state.company.payment_methods,
      item_categories: state => state.company.item_categories,
      items: state => state.company.items,
      training_categories: state => state.company.training_categories,
      training_events: state => state.company.training_events,
      training_machines: state => state.company.training_machines,
      training_muscles: state => state.company.training_muscles,
      time_zones: state => state.company.time_zones,
      surveys: state => state.company.surveys,
      reserve_settings: state => state.company.reserve_settings,
      selectedClient: state => state.company.selectedClient,
      latest_clients: state => state.company.latest_clients,

      // 複数店舗
      company_groups: state => state.company.company_groups,
      all_calendars: state => state.company.all_calendars,
      all_resources: state => state.company.all_resources,

      reload_at: state => state.event.reload_at,
      client_trend_at: state => state.event.client_trend_at,
      cl_payment_at: state => state.event.cl_payment_at
    }),
    blanked_time_zones() {
      let time_zones = [{ id: null, name: '-- 未設定 --' }]
      return time_zones.concat(this.time_zones)
    },
    blanked_company_groups() {
      let company_groups = [{ id: null, name: '-- 全店舗 --' }]
      return company_groups.concat(this.company_groups)
    },
    blanked_reserve_settings() {
      let reserve_settings = [{ id: null, name: '-- 未設定 --' }]
      return reserve_settings.concat(this.reserve_settings)
    },
    clientLoginUrl() {
      if (this.company.hide_gyms_bland) {
        return `${process.env.CUSTOMER_URL}/?campaign_uid=${this.company.uid}`
      } else {
        return `${process.env.CUSTOMER_URL}`
      }
    },
    
    // 各種属性
    // 店舗のカスタムリンク
    company_setting(){
      if(!this.company.settings) return null
      return this.company.settings.company_setting
    },
    // ビジター設定
    visitor_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.visitor_setting
    },
    // 顧客カスタム属性設定
    client_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.client_attrs_setting
    },
    // レッスンカスタム属性設定
    lesson_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.lesson_attrs_setting
    },
    use_taisosei(){
      return this.lesson_setting.weight.use || this.lesson_setting.fat_rate.use || this.lesson_setting.basal_metabolism.use || this.lesson_setting.bmi.use
    },
    use_size(){
      return this.lesson_setting.neck_girth_length.use || this.lesson_setting.upper_arm_girth_length.use || this.lesson_setting.bust_girth_length.use || this.lesson_setting.waist_girth_length.use || this.lesson_setting.hip_girth_length.use || this.lesson_setting.thigh_girth_length.use
    },
    use_big3(){
      return this.lesson_setting.max_weight_bench_press.use || this.lesson_setting.max_weight_squat.use || this.lesson_setting.max_weight_deadlift.use
    },

    // 別店舗顧客制御
    isSameCompany(){
      return !this.client || this.client.company_id === this.company.id
    },

    // 店舗毎に使える要素の制御 //////////////////////////////////////////
    // コース //////////////////////////////////////////
    // 該当店舗用利用可能なコース
    companyUsableCourses(){
      let courses = _.cloneDeep(this.courses)
      return _.filter(courses, (course) => {
        if (!course.company_group_id) return true;
        const targetCompanyGroup = _.find(this.company_groups, ["id", course.company_group_id]);
        return targetCompanyGroup?.cmp_ids.includes(this.company.id) || false;
      });
    },
    // 入会時に利用可能なコース
    // 該当店舗用のコース・メニュー
    joinableCourses(){
      let courses = this.companyUsableCourses
      // 新バージョンかつ契約用
      return _.filter(courses, c => {
        if(c.use_new_version){
          return !c.only_for_buy
        }else{
          return true
        }
      })
    },

    // メニュー //////////////////////////////////////////
    // 該当店舗用利用可能なコース
    companyUsableMenus(){
      let menus = _.cloneDeep(this.menus)
      return _.filter(menus, (menu) => {
        if (!menu.company_group_id) return true;
        const targetCompanyGroup = _.find(this.company_groups, ["id", menu.company_group_id]);
        return targetCompanyGroup?.cmp_ids.includes(this.company.id) || false;
      });
    },

  },
  methods: {
    generateUUID() {
      return window.crypto.randomUUID()
    },
    limitDateOver(limit_date) {
      if (!limit_date) return false
      let today = dayjs()
      return today.isAfter(dayjs(limit_date))
    },
    autoLink(text) {
      return _.isString(text)
        ? text.replace(
            /(https?:\/\/[^\s]*)/g,
            "<a href='$1' target='_blank'>$1</a>"
          )
        : ''
    },
    copyToClipborad(text, ref = null) {
      if (ref) {
        this.$copyText(text, ref).then(
          function (e) {
            window.storeCtl.commit('alert/setSuccess', 'コピーしました')
          },
          function (e) {
            alert('Can not copy')
          }
        )
      } else {
        this.$copyText(text).then(
          function (e) {
            window.storeCtl.commit('alert/setSuccess', 'コピーしました')
          },
          function (e) {
            alert('Can not copy')
          }
        )
      }
    },
    // 住所
    async searchAddress(zipcode){
      let address = null
      const response = await axios.get(`https://api.zipaddress.net/?zipcode=${zipcode}`)
      console.log("response",response)
      if (response && response.data && response.data.data && response.data.data.fullAddress) {
        address = response.data.data.fullAddress
      } else {
        window.storeCtl.commit("alert/setError", "正しい郵便番号を入力してください")
      }
      return address
    },
    
    // document
    filterDocs(docs, doc_ids) {
      return _.filter(docs, doc => {
        return _.includes(doc_ids, doc.id)
      })
    },
    async getClient(client_uid){
      if(!client_uid) return
      return httpClient
        .get(`/cmp/floor/clients/${client_uid}.json`)
        .then(async (res) => {
          if (res.data.status === 'success') {
            return res.data.data.client
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
            return null
          }
        })
    },
    async getDocuments(document_ids) {
      let self = this
      let documents = []
      documents = await httpClient
        .get('/cmp/floor/docs.json', { params: { doc_ids: doc_ids } })
        .then(async res => {
          if (res.data.status === 'success') return res.data.data.docs
        })
      return documents
    },

    async getRelatedLessons(lessson_ids) {
      let self = this
      return await httpClient
        .get(`/cmp/floor/lessons/list_by_ids.json`, {
          params: { lesson_ids: lessson_ids.join(',') }
        })
        .then(async res => {
          if (res.data.status === 'success') {
            return this.relatedLessons = res.data.data.lessons
          } else {
            self.$store.commit('alert/setError', res.data.message)
            return []
          }
        })
    }
  }
}
