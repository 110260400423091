import _ from 'lodash'
import { httpClient } from '~/utils/axios'
import dayjs from 'dayjs'

const getDefaultState = () => {
  return {
    news: [],
    manuals: []
  }
}

export const state = () => getDefaultState()
export const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },

  setDocs(state, payload) {
    let lists = []
    payload.results.forEach(rl => {
      let title = rl.properties.タイトル.title[0].plain_text
      let created_time = rl.properties.作成日時.created_time
      let last_edited_time = rl.properties.最終更新日時.last_edited_time
      let public_date = rl.properties.公開日.date
        ? rl.properties.公開日.date.start
        : null
      let public_url = rl.public_url
      let public_is_new = dayjs(public_date).isAfter(dayjs().subtract(2, 'd'))
        ? true
        : false
      let updated_is_new = dayjs(last_edited_time).isAfter(
        dayjs().subtract(2, 'd')
      )
        ? true
        : false
      let list = {
        title: title,
        public_url: public_url,
        created_time: created_time,
        last_edited_time: last_edited_time,
        public_date: public_date,
        public_is_new: public_is_new,
        updated_is_new: updated_is_new
      }
      lists.push(list)
    })

    if (payload.tag === 'ガイド') {
      lists = _.sortBy(lists, ['title'])
      state.manuals = lists
    } else {
      lists = _.sortBy(lists, ['public_date']).reverse()
      state.news = lists
    }
  }
}
export const getters = {}
export const actions = {
  async getDocs(context, tag) {
    await httpClient
      .post(`/public/notion.json`, {
        tag: tag
      })
      .then(async res => {
        if (res.data.status === 'success') {
          context.commit('setDocs', {
            tag: tag,
            results: res.data.body.results
          })
        } else {
          window.storeCtl.commit('alert/setError', res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit('loading/stop')
      })
  }
}
