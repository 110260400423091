
import _ from 'lodash';
import { mapState } from 'vuex'
import dayjs from 'dayjs';
import Common from '~/mixins/common'
import { httpClient } from '~/utils/axios'
import { client_trend } from '~/utils/models'
import DocumentInputs from '~/components/util/docs/DocumentInputs'
import ClientTrendReport from '~/components/common/client_trend/Report'

export default {
  mixins: [Common],
  components: {
    DocumentInputs,
    ClientTrendReport,
  },
  props: {
  },
  data() {
    return {
      target_date: dayjs().format(),
      client: null,
      edit_client_trend: null,
      menuModal: false,         
    }
  },
  async mounted() {
  },
  computed: {
    selectedDocs(){
      let docs = _.filter(this.edit_client_trend.docs, (d) => {
        return this.edit_client_trend.food_doc_ids.includes(d.id)
      }) 
      return docs
    },
    selectedCmpDocs(){
      let docs = _.filter(this.edit_client_trend.docs, (d) => {
        return this.edit_client_trend.daialy_reply_doc_ids.includes(d.id)
      }) 
      return docs
    },
    clientRatingText(){
      let rating = parseInt(this.edit_client_trend.client_rating) 
      if(rating === 5) return "とても良い"
      if(rating === 4) return "良い"
      if(rating === 3) return "普通"
      if(rating === 2) return "あまり"
      if(rating === 1) return "悪い"
      if(rating === null) return "-"
    },
  },
  filters: {
    boolToDone(val){
      return val ? "出来た" : "出来なかった"
    },
  },
  methods: {
    openMenu(date, client){
      console.log("openMenu", client)
      this.client = _.cloneDeep(client)
      this.target_date = date ? date : dayjs().format()
      this.getClientTrend()
      this.menuModal = true
    },
    prevDate(){
      // 日付確認
      this.target_date = dayjs(this.target_date).subtract(1,"d").format()
      this.getClientTrend()
    },
    nextDate(){
      this.target_date = dayjs(this.target_date).add(1,"d").format()
      this.getClientTrend()
    },
    async getClientTrend(){
      let self = this
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/client_trends/trend.json', {params: {
        client_id: self.client.id,
        date: self.target_date,
      }})
      .then(async (res) => {
        if (res.data.status === 'success') {
          if(res.data.data.client_trend){
            self.edit_client_trend = res.data.data.client_trend
          }else{
            self.edit_client_trend = _.cloneDeep(client_trend)
          }
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
    async updateClientTrend(has_check){
      let self = this
      if(has_check && !self.edit_client_trend.summary_reply){
        window.storeCtl.commit("alert/setError", "コメントを記入して下さい")
        return
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .put(`/cmp/floor/client_trends/${self.edit_client_trend.id}/update_trend.json`, {
        date: self.target_date,
        client_trend: self.edit_client_trend,
      })
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.edit_client_trend = res.data.data.client_trend
          self.$emit("updated", res.data.data.client_trend)
          // window.storeCtl.commit("alert/setSuccess", "更新しました")
          self.menuModal = false
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },

    updateDocs(new_docs){
      console.log("updateDocs",new_docs)
      this.edit_client_trend.docs = this.edit_client_trend.docs.concat(new_docs)
      this.edit_client_trend.docs = _.uniqBy(this.edit_client_trend.docs, "id");
      this.edit_client_trend.daialy_reply_doc_ids = _.map(new_docs, 'id');
    },
  },
}
