
import Common from '~/mixins/common'
import ClPaymentNew from '~/components/common/cl_payment/New'

export default {
  mixins: [Common],
  components: {
    ClPaymentNew,
  },
  props: {
    isDoubleMenu: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  computed: {
    drawer: {
      get(){
        return this.$store.state.devise.drawer
      },
      set(newValue){
        this.$store.commit("devise/setDrawer", newValue)
      }
    },
  },
  watch: {
  },
  methods: {
  },
}
