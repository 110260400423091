
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { task } from '~/utils/models'
import TaskStatusChip from '~/components/common/task/StatusChip'
import DocumentInputs from '~/components/util/docs/DocumentInputs'

export default {
  components: {
    TaskStatusChip,
    DocumentInputs,
  },
  props: {
  },
  data() {
    return {
      showModal: false,
      mode: "create",
      edit_task: null,

      client: null,
    }
  },
  async mounted() {
    let self = this
  },
  computed: {
    selectedDocs(){
      let docs = _.filter(this.edit_task.docs, (d) => {
        return this.edit_task.doc_ids.includes(d.id)
      }) 
      return docs
    },
  },
  methods: {
    // create
    newTask(client){
      let self = this
      self.mode = "create"
      self.edit_task = _.cloneDeep(task)
      self.client = client
      self.showModal = true
    },
    async create(){
      let self = this
      window.storeCtl.commit("loading/start", true)
      await httpClient
        .post(`/cmp/floor/tasks.json`, {
          task: self.edit_task, 
          client_uid: self.client.uid
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.task)
            window.storeCtl.commit("alert/setSuccess", "登録しました")
            self.showModal = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },

    // edit
    async openTask(task_uid) {
      let self = this
      self.mode = "edit"
      window.storeCtl.commit("loading/start")
      await httpClient
        .get(`/cmp/floor/tasks/${task_uid}.json`)
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.client = res.data.data.task.client
            self.edit_task = res.data.data.task
            self.showModal = true
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async update(action_type){
      let self = this
      let message = action_type === "update" ? "保存しました" : "完了にしました"
      window.storeCtl.commit("loading/start")
      await httpClient
        .put(`/cmp/floor/tasks/${self.edit_task.uid}.json`, {
          task: self.edit_task, 
          action_type: action_type
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.task)
            window.storeCtl.commit("alert/setSuccess", message)
            self.showModal = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
    },
    async delete() {
      let self = this
      self.$bvModal.msgBoxConfirm(`${self.edit_task.title}を削除してよろしいですか？`, {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          window.storeCtl.commit("loading/start", true)
          await httpClient
            .delete(`/cmp/floor/tasks/${self.edit_task.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                self.$emit("updated")
                window.storeCtl.commit("alert/setSuccess", "削除しました")
                self.showModal = false
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
        }
      })
    },

    // from other
    async doneFromOther(task){
      this.edit_task = task
      this.$bvModal.msgBoxConfirm(`${this.edit_task.title}を完了してよろしいですか？`, {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm) this.update("done")
      })         
    },
    async deleteFromOther(task){
      this.edit_task = task
      this.delete()
    },

    // other
    updateDocs(new_docs){
      console.log("updateDocs",new_docs)
      this.edit_task.docs = this.edit_task.docs.concat(new_docs)
      this.edit_task.docs = _.uniqBy(this.edit_task.docs, "id");
      this.edit_task.doc_ids = _.map(new_docs, 'id');
    },
  }
}
