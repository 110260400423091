
import _ from 'lodash';
import Common from '~/mixins/common'
import Masters from '~/mixins/masters'
import ClientDetail from '~/components/common/client/Detail'

export default {
  mixins: [Common,Masters],
  components: {
    ClientDetail,
  },
  props: {
    client: Object,
  },
  data() {
    return {
      showSearchModal: false,
      currentClient: null,
    }
  },
  async mounted() {
  },
  computed: {
  },
  methods: {
    openUserSearch(){
      this.getClients("all")
      this.currentClient = null
      this.showSearchModal = true
    },
    async selectClient(client_uid){
      this.$refs.client_detail.open(client_uid)
    },

  },
}
