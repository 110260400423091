import { httpClient } from '~/utils/axios'
import _ from 'lodash'

export default {
  data() {
    return {}
  },
  async mounted() {},
  computed: {
    // チケットまたは店頭払いOKのメニューのみ
    cardOnlyExcludeMenus() {
      return _.filter(this.companyUsableMenus, m => {
        return !(m.pay_as_you_go && m.can_pay_onshop === false)
      })
    },
    optionArrangedCalendars() {
      let calendars = _.cloneDeep(this.calendars)
      if (this.edit_lesson_type !== 'lesson') return calendars
      let selectedMenu = _.find(this.cardOnlyExcludeMenus, [
        'id',
        this.edit_lesson_menu_id
      ])
      console.log('selectedMenu', selectedMenu)
      if (!selectedMenu) return calendars
      calendars.forEach(cal => {
        if (selectedMenu.option_settings.use_calendar_option) {
          let selectedOption = _.find(
            selectedMenu.option_settings.calendar_options,
            ['id', cal.id]
          )
          if (selectedOption) {
            cal.name = `${cal.name}(${selectedOption.price}円)`
          }
        }
      })
      return calendars
    }
  },
  methods: {}
}
