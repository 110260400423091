
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    filterdMenus(menu_ids){
      return _.filter(this.menus, (c) => {
        return menu_ids.includes(c.id)
      })
    },
  },
}
