
import Common from '~/mixins/common'

export default {
  mixins: [Common],
  components: {
  },
  props: {
    client: Object,
    showBuy: {type: Boolean, default: true},
  },
  data() {
    return {
    }
  },
  async mounted() {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    selectedTimezone(time_zone_id){
      let time_zone = _.find(this.time_zones, ["id", time_zone_id])
      if(time_zone){
        return time_zone
      }else{
        return {name: "-"}
      }
    },
  },
}
