
import _ from 'lodash';

export default {
  mixins: [],
  components: {
  },
  props: {
    survey: Object,
  },
  data() {
    return {
      edit_survey: null
    }
  },
  async mounted() {
    this.edit_survey = _.cloneDeep(this.survey)
  },
  watch: {
    survey(val){
      this.survey = val
      this.edit_survey = _.cloneDeep(this.survey)
    },
  },
  computed: {
  },
  methods: {
    questionOptions(question){
      let options = [] 
      question.choices.forEach(ch => {
        options.push({
          value: ch.value,
          text: ch.value,
        })
      })
      return options
    },
    changed(){
      console.log("survey changed")
      this.$emit("updated", this.edit_survey)
    },
  },
}
