
import { httpClient } from '~/utils/axios'
import VueMarkdown from 'vue-markdown';
import _ from 'lodash';
import { mapState } from 'vuex'
import UserIcon from '~/components/util/UserIcon'
import Common from '~/mixins/common'
import ReactionBtn from '~/components/common/chat/ReactionBtn'

export default {
  mixins: [Common],
  components: {
    UserIcon,
    VueMarkdown,
    ReactionBtn,
  },
  props: {
    chat: Object,
  },
  data() {
    return {
      showMenu: false,
      showEditModal: false,
      showReply: false,
      current_chat: null,
      edit_chat: null,
    }
  },
  async mounted() {
    let self = this
    self.current_chat = this.chat
  },
  watch: {
    chat(obj){
      this.current_chat = _.cloneDeep(obj)
    },
  },
  computed: {
    ...mapState({
    }),
    from_user(){
      if(this.current_chat.staff){
        return this.current_chat.staff
      }else{
        return this.current_chat.client
      }
    },
    filterdDocs(){
      let self = this
      let docs = []
      self.current_chat.settings.docs.forEach(d => {
        let doc = _.find(self.current_chat.docs, ["id", d.id])
        if(doc) docs.push(doc)
      })
      return docs
    },
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    fromUser(chat){
      if(chat.staff){
        return chat.staff
      }else{
        return chat.client
      }
    },
    showFromMessage(){
      this.showReply = true
    },
    edit(){
      this.edit_chat = _.cloneDeep(this.current_chat)
      this.showEditModal = true
    },
    async update(){
      let self = this
      window.storeCtl.commit("loading/stop")
      await httpClient
        .put(`/cmp/floor/chat_msgs/${self.edit_chat.uid}.json`, {
          chat_msg: self.edit_chat,
        })
        .then(async (res) => {
          window.storeCtl.commit("loading/stop")
          if (res.data.status === 'success') {
            self.$emit("updated", res.data.data.chat_msg)
            self.showEditModal = false
            self.showMenu = false
          } else {
            window.storeCtl.commit("alert/setError", res.data.message)
          }
        })
        .finally(() => {
          window.storeCtl.commit("loading/stop")
        })
    },
    reply(){
      this.$emit("reply", this.current_chat)
      this.showMenu = false
    },
    quote(){
      this.$emit("quote", this.current_chat)
      this.showMenu = false
    },
    async pin(){
      this.$emit("pined", this.current_chat)
      this.showMenu = false
    },
    async chatDelete(){
      let self = this
      self.$bvModal.msgBoxConfirm("削除します、よろしいですか？", {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          await httpClient
            .delete(`/cmp/floor/chat_msgs/${self.current_chat.uid}.json`)
            .then(async (res) => {
              window.storeCtl.commit("loading/stop")
              if (res.data.status === 'success') {
                self.$emit("updated", res.data.data.chat_msg)
                self.showMenu = false
              } else {
                window.storeCtl.commit("alert/setError", res.data.message)
              }
            })
            .finally(() => {
              window.storeCtl.commit("loading/stop")
            })
        }
      })
    },
  },
}
