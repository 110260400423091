
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import { scrollToTop } from '~/utils/tool'
import Common from '~/mixins/common'
import GoalTermEdit from '~/components/common/goal_term/Edit'
import GoalTermList from '~/components/common/goal_term/List'

export default {
  mixins: [Common],
  components: {
    GoalTermEdit,
    GoalTermList,
  },
  props: {
  },
  data() {
    return {
      // data
      pages: {
        total_count: 1,
        total_pages: 1,
        current_page: 1,
      },
      freeword: null,
      current_goal_terms: [],
      status_eq_any: ["inprocess","done"],
      goalTermStatusOptions: [
        {text: "進行中", value: "inprocess"},
        {text: "完了", value: "done"},
        {text: "ペンディング", value: "over"},
      ],
    }
  },
  async mounted() {
    this.search()
  },
  watch: {
  },
  computed: {
  },
  methods: {
    async clear() {
      this.freeword = null
      this.status_eq_any = ["inprocess","done"]
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async autoSearch(){
      this.pages = {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
      }
      await this.search()
    },
    async search(){
      let self = this
      // set params
      let params = {
        "q[title_cont]": self.freeword,
        status_eq_any: self.status_eq_any,
        client_id: self.selectedClient.id,
        page: self.pages.current_page,
      }
      window.storeCtl.commit("loading/start")
      await httpClient
      .get('/cmp/floor/goal_terms.json', {params: params})
      .then(async (res) => {
        console.log("current_goal_terms", res.data.data.goal_terms)
        if (res.data.status === 'success') {
          self.current_goal_terms = res.data.data.goal_terms
          self.pages = res.data.data.pages
          scrollToTop()
        } else {
          window.storeCtl.commit("alert/setError", res.data.message)
        }
      })
      .finally(() => {
        window.storeCtl.commit("loading/stop")
      })
    },
  }
}
