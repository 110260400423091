import { render, staticRenderFns } from "./PartPrice.vue?vue&type=template&id=4075cb18&scoped=true&lang=pug"
import script from "./PartPrice.vue?vue&type=script&lang=js"
export * from "./PartPrice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4075cb18",
  null
  
)

export default component.exports